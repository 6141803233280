import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SigninComponent } from './components/signin/signin.component';
import { HomeComponent } from './components/home/home.component';
import { OrdersComponent } from './components/orders/orders.component';
import { DetailOrderComponent } from './components/detail-order/detail-order.component';
import { PendingOrdersComponent } from './components/pending-orders/pending-orders.component';
import { PendingOrdersForDispatchComponent } from './components/pending-orders-for-dispatch/pending-orders-for-dispatch.component';
import { OrdersDeliverStoreComponent } from './components/orders-deliver-store/orders-deliver-store.component';
import { ProductsComponent } from './components/products/products.component';
import { BranchesComponent } from './components/branches/branches.component';
import { ActivityComponent } from './components/branches/activity.component';
import { ConfigComponent } from './components/branches/config.component';
import { ReportPaymentMethodComponent } from './components/report-payment-method/report-payment-method.component';
import { ReportOrderTrackComponent } from './components/report-order-track/report-order-track.component';
import { ReportSummaryComponent } from './components/report-summary/report-summary.component';


const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'home', pathMatch: 'full', component: PendingOrdersComponent },
      { path: 'home/:time', pathMatch: 'full', component: PendingOrdersComponent },
      { path: 'signin', pathMatch: 'full', component: SigninComponent },
      { path: 'orders', pathMatch: 'full', component: OrdersComponent },
      { path: 'activity', pathMatch: 'full', component: ActivityComponent },
      { path: 'orders-store', pathMatch: 'full', component: OrdersDeliverStoreComponent },
      { path: 'products', pathMatch: 'full', component: ProductsComponent },
      { path: 'branches', pathMatch: 'full', component: BranchesComponent },
      { path: 'config-branch', pathMatch: 'full', component: ConfigComponent },
      { path: 'pending', pathMatch: 'full', component: PendingOrdersComponent },
      { path: 'dispatch', pathMatch: 'full', component: PendingOrdersForDispatchComponent },
      { path: 'order/:id', pathMatch: 'full', component: DetailOrderComponent },
      { path: 'report-payment-method', pathMatch: 'full', component: ReportPaymentMethodComponent },
      { path: 'report-order-track', pathMatch: 'full', component: ReportOrderTrackComponent },
      { path: 'report-summary', pathMatch: 'full', component: ReportSummaryComponent },
     /* { path: 'location', pathMatch: 'full', component: MyLocationComponent },
      { path: 'menu', pathMatch: 'full', component: OnlineMenuComponent },
      { path: 'item/:id', pathMatch: 'full', component: ItemDetailComponent },
      { path: 'edit-item/:id/:item_id', pathMatch: 'full', component: ItemDetailComponent },
      { path: 'cart', pathMatch: 'full', component: CartComponent },
      { path: 'confirm', pathMatch: 'full', component: ConfirmOrderComponent },
      { path: 'order-resume/:id', pathMatch: 'full', component: OrderResumeComponent }*/

    ]
  }
];

  @NgModule({

    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],

  })
  export class BusinessRoutingModule { }

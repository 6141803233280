import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { RestService } from './../../../services/rest.service';
import { StateService } from './../../../services/state.service';

import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MenuController, ModalController, AlertController, LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-pending-orders',
  templateUrl: './pending-orders.component.html',
  //templateUrl: './../orders/orders.component.html',
  styleUrls: ['./pending-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendingOrdersComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['fechaRegistro', 'customerName', 'customerPhone', 'address', 'storePickup', 'totalPay', 'actions'];
  dataSource$ = new BehaviorSubject([]);
  dataForPickupSource$ = new BehaviorSubject([]);
  dataDomicileSource$ = new BehaviorSubject([]);
  routeSubscription = null;
  subsidiary = null;
  title = "Pendientes por recibir";

  constructor(private rest: RestService, protected route: ActivatedRoute, private router: Router,
    private menu: MenuController, private state: StateService) { }

  ngOnInit() {
    this.routeSubscription = this.route.url.subscribe(async (url) => {
      this.reload();
      this.openMenu();
    });

    let token = this.state.getToken();
    if(!token) {
      this.router.navigateByUrl('/signin');
    }
  }
  ngOnDestroy() {
      for(const property in this) {
        if(this[property] && typeof this[property]['unsubscribe'] !== 'undefined'){
          this[property]['unsubscribe']();
        }
      }
  }
  ionViewWillEnter(){
    this.reload();
    this.openMenu();
  }
  reload(){
    this.subsidiary = this.state.getSubsidiary();
    console.log(this.subsidiary);
    //status: 'pending',
    this.rest.post('domicile/orders', { status: 'pending', 'subsidiary': this.subsidiary.id }).subscribe((response) => {
      //console.log(response)
      this.dataSource$.next(response.items);
    });

    this.rest.post('domicile/orders', { status: 'for-pickup', 'subsidiary': this.subsidiary.id }).subscribe((response) => {
      //console.log(response)
      this.dataForPickupSource$.next(response.items);
    });

    this.rest.post('domicile/orders', { status: 'for-dispatch', 'subsidiary': this.subsidiary.id }).subscribe((response) => {
      //console.log(response)
      this.dataDomicileSource$.next(response.items);
    });
  }
  openMenu(){
    this.menu.enable(true, 'first');
    this.menu.open();
  }
}

import {  Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {
  transform( value: any, symbol?: string) {
    return this.moneyMask(value);
  }
  /*transform( value: any, currencyCode?: string,
    display: 'code'|'symbol'|'symbol-narrow'|boolean = 'symbol',
    digits?: any,
    locale?: string): string | null {
      if (!value) return null;
      if (!currencyCode) currencyCode = '$';
      if (!display)display = 'symbol';
      if (!digits) digits = '.0';
      return  super.transform(value/10000, currencyCode, display, digits, locale);
    }*/
    unmask(v: any) {
      if (!v) {
        return '';
      }
      let tmp = '' + v;
      tmp = tmp.replace(/,/gi, '');
      return tmp;
    }

    moneyMask(v: any): string {
      if (!v) {
        return '0';
      }
      let tmp = '' + v;
      let decimal = '';
      let nominal = '';

      tmp = tmp.replace(/,/gi, '');
      if (tmp.indexOf('.') > -1) {
          const parts = tmp.split('.');
          decimal = '.' + parts[1].substr(0, 2);
          nominal = parts[0];
      } else{
        nominal = tmp;
      }
      nominal = nominal.replace(/\D/gi, '');
      if (nominal.length > 3) {
        let val = '';
        let j = 1;
        for (let i = (nominal.length - 1); i >= 0; i--) {
          val =  nominal[i] + '' + val;
          if ((j) % 3 === 0 && (i) > 0) {
            val = ',' + val;
          }
          j++;
        }
        nominal = val;
      }
      return nominal + decimal;
    }

}

import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, NgZone, ViewRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { RestService } from '../../../services/rest.service';
import { StateService } from '../../../services/state.service';
import { FirestoreService } from '../../../services/firestore.service';
import {  ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import * as humanizeDuration from "humanize-duration";

declare var google: any;
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styles: [` `],
  changeDetection: ChangeDetectionStrategy.OnPush  
})
export class ConfigComponent implements OnInit, OnDestroy, AfterViewInit {

  firestoreActivity = null;
  branches = [];
  activities = {};
  status = 'online';
  subsidiary = null;

  constructor(private firestoreService: FirestoreService, private rest: RestService, private state: StateService, 
    protected route: ActivatedRoute, private cd: ChangeDetectorRef) { 

  }

  ngOnInit() {
   const status = localStorage.getItem('status');
   console.log(status, '123');
   if(!status){
       this.status = 'online';
   }else{
    this.status = status;
   }

  }
  deactivated(){
    this.syncroStatus('busy');
  }
  activated(){
    this.syncroStatus('online');
  }
  syncroStatus(status){
      console.log('syncro...', status)
    this.subsidiary = this.state.getSubsidiary();
    localStorage.setItem('status', status);
    const lh = firebase.firestore.FieldValue.serverTimestamp();
    this.firestoreService.createOrUpdateDocument('stores_activity', this.subsidiary.id+"", { last_online: lh, status: status, msg: 'pong'});
    this.status = status;
    //this.detectChanges();
  }
  getLastTime(id){
    //console.log(this.activities)
    if(this.activities[id]){
      //console.log(this.activities[id].seconds)
      let dif = firebase.firestore.Timestamp.now().seconds - this.activities[id].seconds;
      if(dif == 0){
        dif = 1;
      }
      return humanizeDuration(dif*1000);
    }
    return null;
  }
  
  ngAfterViewInit() {
   // this.initAutocomplete();
  } 

  
  ngOnDestroy() {
    for (const property in this) {
      if (this[property] && typeof this[property]['unsubscribe'] !== 'undefined') {
        this[property]['unsubscribe']();
      }
    }
  }


  detectChanges(){
    if (this.cd !== null && this.cd !== undefined &&
          !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

}

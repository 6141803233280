import { Component, OnInit } from '@angular/core';
import { NavParams, MenuController, ToastController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Platform } from '@ionic/angular';
import { auth } from 'firebase/app';
import { UserInfo} from 'firebase/app'
import { cfaSignIn, mapUserToUserInfo} from 'capacitor-firebase-auth';
import { AuthService } from './../../../services/auth.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-modal-register',
  template: `
  <div class="register-email">
  <div class="logo bg-img" >
      <img src="assets/mrbross.png">
  </div>
  <ion-list class="inputs-register" lines="full">
   <span class="message-info mb-3" *ngIf="state=='email'">{{'Recover password'|translate}}</span>
   <span class="message-info mb-3" *ngIf="state=='code'">{{'Validation code'|translate}}</span>
   <span class="message-info mb-3" *ngIf="state=='passwd'">{{'Change your password'|translate}}</span>
   
   <form [formGroup]="loginForm" autocomplete="off" class="register-email" *ngIf="state=='email'">
      <ion-item class="input-item mt-3 mb-2">
                <ion-label [position]="position">{{'Email'|translate}}</ion-label>
                <ion-input type="email" formControlName="email" placeholder="micorreo@correo.com"  clearInput autocapitalize="off" inputmode="email"></ion-input>
      </ion-item>
     
  <ngx-recaptcha2 #captchaElem *ngIf="recaptcha"
      [siteKey]="siteKey"
      (reset)="handleReset()"
      (expire)="handleExpire()"
      (load)="handleLoad()"
      (success)="handleSuccess($event)"
      [useGlobalDomain]="false"
      size="normal"
      hl="es"
      theme="light"
      type="image"
      formControlName="recaptcha">
    </ngx-recaptcha2>

   </form>
   <form [formGroup]="codeForm" autocomplete="off" class="register-email" *ngIf="state=='code'">
      <ion-item class="input-item mt-3">
                <ion-label [position]="position">{{'Code'|translate}}</ion-label>
                <ion-input type="number" formControlName="code"   clearInput autocapitalize="off" ></ion-input>
      </ion-item>
   </form>

   <form [formGroup]="passwdForm" autocomplete="off" class="register-email" *ngIf="state=='passwd'">
      <ion-item class="input-item mt-3">
                <ion-label [position]="position">{{'Password'|translate}}</ion-label>
                <ion-input type="text" formControlName="passwd"   clearInput autocapitalize="off" ></ion-input>
      </ion-item>
   </form>

    <div style="margin-top: 15px; margin-bottom: 45px">
        <ion-button *ngIf="state=='email'" (click)="submit()" [disabled]="!isValid()" color="primary" shape="round" size="small" expand="block">{{'Next'|translate}}</ion-button>
        <ion-button *ngIf="state=='code'" (click)="validate()" [disabled]="!isValidCodeForm()" color="primary" shape="round" size="small" expand="block">{{'Next'|translate}}</ion-button>
        <ion-button *ngIf="state=='passwd'" (click)="changePasswd()" [disabled]="!isValidPasswdForm()" color="primary" shape="round" size="small" expand="block">{{'Next'|translate}}</ion-button>
        <ion-button class="mt-2" (click)="close()" color="dark" shape="round" size="small" expand="block">{{'Cancel'|translate}}</ion-button>
    </div>
  </ion-list>
  </div>
  `
})
export class ModalRecoverPasswdComponent implements OnInit {
  loginForm: FormGroup;
  codeForm: FormGroup;
  passwdForm: FormGroup;
  position = 'fixed';
  state = 'email'; //email, code, pawwsd
  siteKey = environment.siteKey;
  recaptcha = environment.recaptcha;
  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ]
  };

  constructor(public menuCtrl: MenuController,
              public autService: AuthService, private modalController: ModalController,
              private platform: Platform, private navParams: NavParams) {
  }

  ngOnInit() {
    const email = this.navParams.get('email');
    const controls = {
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ]))
    };

    if(this.recaptcha){
      controls['recaptcha'] = new FormControl('', Validators.required);
    }
    this.loginForm = new FormGroup(controls);

    this.codeForm = new FormGroup({
      'code': new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ]))
    });

    this.passwdForm = new FormGroup({
      'passwd': new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ]))
    });
  }

  isValid(){
    return this.loginForm.valid && this.loginForm.dirty && this.state == 'email';
  }

  isValidPasswdForm(){
    return this.passwdForm.valid && this.passwdForm.dirty && this.state == 'passwd';
  }

  submit(){
    let recapt = "";
    if(this.recaptcha){
      recapt = this.loginForm.value.recaptcha;
    }
    this.autService.sendEmailCodeVerification(this.loginForm.value.email, recapt, true).then( (result: any) => {
 
      if(result.code == 'OK'){
        this.autService.restService.displayMsg('Se envió un código de verificación a su correo.');
        this.state = 'code';
      }else{
        this.autService.restService.displayError(result.msg);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  isValidCodeForm(){
    return this.codeForm.valid && this.codeForm.dirty;
  }

  changePasswd(){
    this.autService.changePasswd({ email: this.loginForm.value.email, passwd: this.passwdForm.value.passwd,
       code: this.codeForm.value.code }).then( (result: any) => {
      if(result.code == 'OK'){
        this.loginForm.reset({});
        this.codeForm.reset({});
        this.passwdForm.reset({});
        this.modalController.dismiss();
      }else{
        this.autService.restService.displayError('Código no valido');
      }
    });    
  }


  validate(){
    this.autService.validateCode(this.loginForm.value.email, this.codeForm.value.code).then( (result: any) => {
      if(result){
        this.state = 'passwd';
        //this.modalController.dismiss( { email: this.loginForm.value.email });
      }else{
        this.autService.restService.displayError('Código no valido');
      }
    });
  }

  close(){
    this.modalController.dismiss( { cancel: true });
  }

  handleLoad() {
  }
  handleReset() {
  }
  handleExpire() {
  }
  handleSuccess($event) {
    this.loginForm.markAsDirty();
  }

}

import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { Geolocation } = Plugins;
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { StateService } from './state.service';
import { RestService } from './rest.service';
// import { CamelizePipe } from 'ngx-pipes';
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private geoCoder;
  private locationCache: any = {};
  directionsService = null;

  constructor( private rest: RestService, private state: StateService, private mapsApiLoader: MapsAPILoader) {

     this.mapsApiLoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
  }

  private camelize(value: string): string {
    return value;
    // this.camelizePipe.transform(value);
  }

  getInfoDirections(origin, destination, travelMode, transitOptions, drivingOptions){
    if (this.directionsService === null) {
        this.directionsService = new google.maps.DirectionsService;
    }
    return new Promise((resolve, reject) => {
      this.directionsService.route({
          origin: origin,
          destination: destination,
          travelMode: travelMode,
          transitOptions: transitOptions,
          drivingOptions: drivingOptions,
          //waypoints: this.waypoints,
          //optimizeWaypoints: this.optimizeWaypoints,
          //provideRouteAlternatives: this.provideRouteAlternatives,
          //avoidHighways: this.avoidHighways,
          //avoidTolls: this.avoidTolls,
        }, (response: any, status: any) => {
          resolve(response);
        });
    });
  }

  async getUserAddress(){
    return new Promise((resolve, reject) => {
      const data = this.state.getData();
      this.rest.post('domicile/user-address', { personId: data['asociado_id']}).subscribe((result) => {
        if(result.items && result.items.length > 0) {
          this.state.persist('user-address', result.items);
          resolve(result.items);
        }else{
          resolve([]);
        }
      });
    });
  }

  async getAddressUbication(modalController, cmp){
    const modal = await modalController.create({
     component: cmp, //ModalUbicationComponent,
    });
    modal.onWillDismiss().then((result) => {
      if(result.data && !result.data.cancel){
        try{
          //localStorage.setItem('coverage', result.data.coverage);
          //localStorage.setItem('subsidiary', result.data.subsidiary.id);
          //this.router.navigate(['/menu']);
        }catch(e){
          console.log(e)
        }
      }else{
        
      }
    });
    await modal.present();     
  }

  async getNearbySubsidiaries(modalController, cmp){
    const modal = await modalController.create({
     component: cmp, //ModalUbicationComponent,
    });
    modal.onWillDismiss().then((result) => {
      if(result.data && !result.data.cancel){
        try{
          localStorage.setItem('coverage', result.data.coverage);
          localStorage.setItem('subsidiary', result.data.subsidiary.id);
          //this.router.navigate(['/menu']);
        }catch(e){
          console.log(e)
        }
      }else{
        
      }
    });
    await modal.present();     
  }

  private cacheLocation(location: string, coordinates: any) {
    this.locationCache[this.camelize(location)] = coordinates;
  }

  private isLocationCached(location): boolean {
    return this.locationCache[this.camelize(location)];
  }

  private geocodeLocation(location: string): Observable<any> {
    if (!this.geoCoder) {
      this.geoCoder = new google.maps.Geocoder;

    }
    
    return new Observable((observer) => {
      this.geoCoder.geocode({address: location}, (result, status) => {
        if (status === 'OK') {
          console.log(result);
          const geometry = result[0].geometry.location;
          const coordinates = {lat: geometry.lat(), lng: geometry.lng()};
          this.cacheLocation(location, coordinates);
          observer.next(coordinates);
        } else {
          observer.error('Location could not be geocoded');
        }
      });
    });
  }

  public getGeoLocation(location: string): Observable<any> {
    if (this.isLocationCached(location)) {
      return observableOf(this.locationCache[this.camelize(location)]);
    } else {
      return this.geocodeLocation(location);
    }
  }

  geocodeLocationReverse(lat: any, lng: any): Observable<any> {
    if (!this.geoCoder) {
      this.geoCoder = new google.maps.Geocoder;

    }
    return new Observable((observer) => {
      const latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};

      this.geoCoder.geocode({location: latlng}, (result, status) => {
        if (status === 'OK') {
          //console.log(result);
          /*const geometry = result[0].geometry.location;
          const coordinates = {lat: geometry.lat(), lng: geometry.lng()};*/
         // this.cacheLocation(location, coordinates);
          observer.next(result);
        } else {
          observer.error('Location could not be geocoded');
        }
      });
    });
  }
  public getAddress(lat: any, lng: any): Observable<any> {
      return this.geocodeLocationReverse(lat, lng);
  }

  public distance(points: any[]) {
    const l = points.length - 1;
    let d = 0;
    for (let i = 0; i < l; i++) {
      if (points[i + 1]) {
        d += this.calcaulateDistance(points[i], points[i + 1]);
      }
    }
  }

  createPoint(lat, lng){
    return new google.maps.LatLng(lat, lng);
  }

  calcaulateDistance(p1, p2) {
    return ((google.maps.geometry.spherical.computeDistanceBetween(p1, p2)));
  }

  async findCurrentCountry(){
      const ubication: any = await this.getCurrentPosition();
      
      const cacheCountry: any = await this.state.getJson('country');
      const cacheUbication: any = await this.state.getJson('ubication');
      if(cacheCountry.value){
        if(cacheUbication.value && cacheUbication.value.lat){
        // distancia en metros, si la distancia es mayor a 2 km vuelve a consultar el pais
          let dist = Math.abs(this.calcaulateDistance(new google.maps.LatLng(ubication.lat, ubication.lng),
                                                 new google.maps.LatLng(cacheUbication.value.lat, cacheUbication.value.lng)));
          console.log('distancia: ', dist)
          if(dist > 2000){
            this.locateCountry(ubication);
          }
        }else{
          this.locateCountry(ubication);
        }
      }else{
        this.locateCountry(ubication);
      }

      this.state.persist('ubication', ubication);      
  }

  async locateCountry(ubication){
    //console.log('locate ubication')
    return new Promise((resolve, reject) => {
      this.getAddress(ubication.lat, ubication.lng).subscribe((res) => {
        //console.log(res)
        let found = false;
        for(const site of res){
          for(const p of site.address_components){
            if(p.types.indexOf('country')>=0){
              console.log(p.long_name)
              this.state.persist('country', p);
              found = true;
              break;
            }
          }
          if(found){
            break;
          }
        }
        resolve(true);
      });       
    })
      
  }

  async getNearbySubsidiariesOrNearestBranch(){
    return new Promise(async (resolve, reject) => {


    const ubication: any = await this.state.getJson('ubication');
      this.rest.post('domicile/nearby-subsidiaries', ubication.value).subscribe((result) => {
        if(result.items && result.items.length > 0){
          this.state.persist('subsidiary', { hasCoverage: true, branch:  result.items[0]});
          resolve({ hasCoverage: true, branch:  result.items[0]});
          /*if(result.items.length == 1){
            //this.hasCoverage = true;
            //this.subsidiary = result.items[0];          
            //this.rest.displayMsg('Sucursal: ' + result.items[0].nombre);
          }else{
            for(const s of result.items){
              if(s.geo_cobertura){
                this.subsidiaries.push(this.extractPolygon(s.geo_cobertura));
              }
            }
          }*/
          //this.cd.detectChanges();
        }else{
          this.rest.post('domicile/nearest-branch', ubication.value).subscribe((result) => {
            if(result.items && result.items.length > 0){
              this.state.persist('subsidiary', { hasCoverage: false, branch: result.items[0]});

              //this.subsidiary = result.items[0];
              //this.rest.displayMsg('Aprox: : ' + result.items[0].nombre);
            }
            resolve();
          });
        }
        
      });
    });
  }
  async getCurrentPosition(){
    const position = await Geolocation.getCurrentPosition();
    return { lat: position.coords.latitude, lng: position.coords.longitude  };
  }

  getInfoFromPlace(place){
    const info = {};

    info['coords'] = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
    info['site'] = place.name;
    info['place_id'] = place.place_id;
    info['address'] = place.formatted_address;
    if(place.address_components){
      for(const comp of place.address_components){
         if(this.isCountry(comp)){
           info['country'] = { name: comp.long_name, short: comp.short_name };
         }
         if(this.isState(comp)){
           info['state'] = comp.long_name;
         }
         if(this.isCity(comp)){
           info['city'] = comp.long_name;
         }
      }
    }
    return info;
  }

  private isCity(component){
    if(component.types.indexOf('administrative_area_level_2') >= 0 || component.types.indexOf('locality') >= 0){
      return true;
    }
    return false;
  }

  private isState(component){
    if(component.types.indexOf('administrative_area_level_1') >= 0){
      return true;
    }
    return false;
  }

  private isCountry(component){
    if(component.types.indexOf('country') >= 0){
      return true;
    }
    return false;
  }
}

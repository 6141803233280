import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ModalController, LoadingController, ActionSheetController, AlertController, ToastController, Platform } from '@ionic/angular';

import { StateService } from './state.service';
// import { ElectronService } from 'ngx-electron';
// import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
// import { LogComponent } from '../shared/components/log/log.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  public httpEvents$ = new BehaviorSubject(null);
  constructor(private http: HttpClient,
      private stateService: StateService,
      private router: Router,
      private alertCtrl: AlertController,
      private translateService: TranslateService,
      public loadingController: LoadingController
    // , private _electronService: ElectronService
    // private toastrService: ToastrService,
    // 

  ) { }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: 'procesando...',
      duration: 0
    });
    loading.present();
    return loading;
  }
  // constructor(private http: HttpClient, private store: Store<State>, private ngbModal: NgbModal) {}

  getHeaders() {
    
      let headers = new HttpHeaders();
      let token = this.stateService.getToken();
      if(token){
        headers = headers.append('Authorization', token);
      }
      headers = headers.append('Accept', 'application/json');
      headers = headers.append('Content-Type', 'application/json');
      return headers;
   
  }
  getUrlImages() {
    return environment.urlImages;
  }
  getUrlBase() {
    return environment.baseUrl;
  }
  post(uri: string, params: {}, extra = null) {
    /* if (this._electronService.isElectronApp) {
      let pong: string = this._electronService.ipcRenderer.sendSync('message-web', 'jujujuu');
      console.log(pong);
    }*/
    let token = this.stateService.getToken();
      if(!token){
       // headers = headers.append('Authorization', token);
       this.router.navigate(['/signin']);
      }

    return this.http.post(environment.url + '/' + uri, {
     data: params
    }, { headers: this.getHeaders()}).pipe(tap( (response: any) => {
      if (response.event) {
        console.log('user o session invalid');
        this.httpEvents$.next(response.event);
      }
      if (!response.success ) {
        if (response.msg) {
          this.translateService.get(response.msg).subscribe((msg) => {
            this.displayError(msg)
          });
          console.log('invalid...')
          if(response.msg == 'Token no valido' || response.msg == 'SESSION_UPDATE') {
            this.stateService.clear();
            localStorage.setItem('status', 'offline');
            //this.router.navigate(['/signin']);
            document.location.href = '/signin'
          }
        } else {
          console.error('Ocurrio un error en la peticion: ' + uri, params);
          this.displayError('error request')
        }
      } else {
        if (response.msg) {
          this.translateService.get(response.msg).subscribe((msg) => {
            this.displayMsg(msg);
          });

        }
      }
     }));
  }
  postRemote(url: string, params: {}) {
    return this.http.post(url, {
     data: params
    });
  }
  getRemote(url: string) {
    
    return this.http.get(url, { headers: { 'Content-Type': 'text/html'}});
  }
  postAnonimus(uri: string, params: {}) {
    return this.http.post(environment.url + '/' + uri, {
     data: params
    }).pipe(tap( (response: any) => {
      if (response.event) {
        if (response.event === 'sesion_destroy') {
          const payload = { };
        }
      }
     }));
  }
  search(uri: string, params: {}) {
    const headers = this.getHeaders();
    return this.http.post(environment.url + '/' + uri, {
      data: params
     } , { headers })
     .pipe(map((response: any) => {
      // this.cache[uri] = response.items;
       return response.items;
     }));
  }

  getAnonimus(uri: string, params: {}) {

    return this.http.get(environment.baseUrl + '/' + uri )
  }

  postLocal(uri: string, params: {}, extra = null) {
    /* if (this._electronService.isElectronApp) {
      let pong: string = this._electronService.ipcRenderer.sendSync('message-web', 'jujujuu');
      console.log(pong);
    }*/

    return this.http.post('http://localhost:3000/' + uri, {
     data: params
    }).pipe(tap( (response: any) => {
   
      if (!response.success ) {
        if (response.msg) {
          this.translateService.get(response.msg).subscribe((msg) => {
            this.displayError(msg)
          });
        } else {
          console.error('Ocurrio un error en la peticion: ' + uri, params);
          this.displayError('error request')
        }
      } else {
        if (response.msg) {
          this.translateService.get(response.msg).subscribe((msg) => {
            this.displayMsg(msg);
          });
        }
      }
     }));
  }

  async displayError(message : string) {
    if(!message){
      return;
    }
    let alert  = await this.alertCtrl.create({ header : 'Error',
       subHeader       : message,
       buttons      : ['Ok']
    });
    alert.present();
  }

  async displayMsg(message : string, header = '') {
    if(!message){
      return;
    }
    let alert  = await this.alertCtrl.create({ header : header,
       subHeader       : message,
       buttons      : ['Ok']
    });
    alert.present();
  }

  /*openLogsModal() {
    const modalRef = this.ngbModal.open(LogComponent, {});
    return modalRef;
  }*/
}

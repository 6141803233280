import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import * as firebase from "firebase";
// import { environment } from './../environments/environment';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { BusinessModule } from "./business/business.module";
import { MoneyPipe } from './lib/pipe/money.pipe';

import { registerLocaleData } from '@angular/common';
import localeEsCO from '@angular/common/locales/es-CO';
registerLocaleData(localeEsCO);

/*export const firebaseConfig = {
    apiKey: "AIzaSyAXUAMw7sa5FdilWHNAdiYoiYDZ8q3zEMg",
    authDomain: "mrbross-dc841.firebaseapp.com",
    databaseURL: "https://mrbross-dc841.firebaseio.com",
    projectId: "mrbross-dc841",
    storageBucket: "mrbross-dc841.appspot.com",
    messagingSenderId: "532904045935",
    appId: "1:532904045935:web:f5a904b3d0ff1ddf704c9a"
};*/
import * as moment from 'moment'
import 'moment/locale/es';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'  // without this line it didn't work
moment.locale('es')

let firebaseCnf = environment.firebaseConfig;

if (window.location.host.indexOf('source-solutions') !== -1 || window.location.host.indexOf('localhost:4200') !== -1 ) {
  firebaseCnf =  {
      apiKey: "AIzaSyCl86aiGDH1y2rzeXFWc75r4F5eUIw21XA",    
      authDomain: "mrbrossapptest.firebaseapp.com",    
      databaseURL: "https://mrbrossapptest-default-rtdb.firebaseio.com",    
      projectId: "mrbrossapptest",    
      storageBucket: "mrbrossapptest.appspot.com",    
      messagingSenderId: "1053546149035",    
      appId: "1:1053546149035:web:734ebef937de29e9c933ee",
      measurementId: "" 
    };
}
//console.log(firebaseCnf)

export const firebaseConfig = firebaseCnf;

export function createTranslateLoader(http: HttpClient) {
  const target = '';
  return new TranslateHttpLoader(http, /*'./assets/i18n/'*/
               './assets/i18n/', '.json');

              // 'https://awscrm.source-solutions.co/i18n/' + target, '.json?_=' + (new Date()).getTime());
}


@NgModule({
  declarations: [AppComponent,
    MoneyPipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    NgxCaptchaModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD1ILMEoRoBqjwvabE8oZJTD_TB6BNWXrw', // 'AIzaSyBcBN94qFmTUlKxAVTuKzYEWzaYc2bvpUY',
      libraries: ['places', 'drawing', 'geometry', 'directions']
    }),
    AgmDirectionModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BusinessModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}


import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MapService } from './../../../services/map.service';
import { RestService } from './../../../services/rest.service';
import { OrderService } from './../../../services/order.service';
import { PrintService } from './../../../services/print.service';
import { FirestoreService } from './../../../services/firestore.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ModalObservationsClientComponent } from './modal-observations-client.component';

@Component({
  selector: 'app-detail-order',
  templateUrl: './detail-order.component.html',
  styleUrls: ['./detail-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailOrderComponent implements OnInit, OnDestroy {
  requestStates$ = new BehaviorSubject([]);
  center = null; //{ lat: 3.482569, lng: -76.521834 };

  origin = null; // = { lat: 3.480727, lng: -76.524672 };
  target = null; //{ lat: 3.488481, lng: -76.528958 };
  orderId = null;
  orderData = null;
  deliverer = null;
  driver = null;
  stimatedTime = 0;
  requestStates = null;
  firestoreSubscription = null;
  ubicationDeliverer = null;
  drivers = [];
  times = [20, 30, 40, 50, 60];
  commentsClient: any;
  public travelMode: string = 'DRIVING'
  public renderOptions = {
    suppressMarkers: true,
  }


  public markerOptions = {
    origin: {
      icon: 'assets/icon.png',
      draggable: false,
    },
    destination: {
      icon: 'assets/imgs/casa.png',
      // label: 'MARKER LABEL',
      // opacity: 0.8,
    },
  };

  constructor(private map: MapService, private order: OrderService,
    private loadingController: LoadingController, private printService: PrintService,
    private rest: RestService, private firestoreService: FirestoreService,
    private router: Router, public alertController: AlertController,
    private route: ActivatedRoute, private cd: ChangeDetectorRef, private modalController: ModalController) { }

  ngOnDestroy() {
    for (const property in this) {

      if (this[property] && typeof this[property]['unsubscribe'] !== 'undefined') {
        console.log('destroy')
        this[property]['unsubscribe']();
      }
    }
  }
  ngOnInit() {
    this.route.paramMap.subscribe(async (params) => {
      this.orderId = params.get('id');
      //this.itemId = params.get('item_id');
      //this.product = this.state.getProductById(this.orderId);
      const loading = await this.loadingController.create({
        message: 'Obteniendo estado de la orden',
      });
      await loading.present();
      this.rest.post('domicile/order', { id: this.orderId }).subscribe(async (result) => {
        loading.dismiss();
        this.orderData = result.item.data;
        this.commentsClient = this.orderData.commentsClient;

        console.log(this.orderData);


        if (this.orderData.driver) {
          this.driver = this.orderData.driver;
        }
        if (!this.orderIsFinished()) {
          this.searchFirestoreOrder(this.orderId);
          console.log(this.orderData)
          this.requestStates$.next(this.orderData.log);
          this.drivers = await this.getDrivers();
        } else {
          console.log(this.orderData.log)
          this.requestStates$.next(this.orderData.log);
        }

        //console.log(this.orderData)
        this.origin = { lat: parseFloat(this.orderData.subInfo.lat), lng: parseFloat(this.orderData.subInfo.lng) };
        if (!this.orderData.storePickup) {
          this.target = { lat: parseFloat(this.orderData.address.lat), lng: parseFloat(this.orderData.address.lng) };
        }
        this.center = this.origin;
        this.detectChanges();
      }, err => {
        loading.dismiss();
      });
    });
  	/*this.map.getInfoDirections(this.origin, this.target, this.travelMode, null, null).then((response) => {
  		console.log(response)
  	})*/
  }
  getQtTopping(topp, item){
    if(topp.qt){
      return topp.qt* item.qt;
    }
    return 1 * item.qt;
  }
  acceptOrderDisabled() {
    if (this.stimatedTime == 0 && !this.orderData.storePickup) {
      return true;
    }
    return false;
  }

  async cancelOrder() {
    const alert = await this.alertController.create({
      header: 'Cancelación de pedido',
      message: 'Esta seguro que desea cancelar este pedido?',
      inputs: [
      { name: 'reason', type: 'text', placeholder: 'Motivo' }
      ],
      buttons: [
      { text: 'Cancelar', role: 'cancel', cssClass: 'secondary' },
      {
        text: 'Aceptar',
        cssClass: 'secondary',
        handler: (data: any) => {
          console.log('.....', data.reason)
          if (data.reason == '') {
            return false;
          }
          this.rest.post('domicile/order-cancel', { id: this.orderId, type: 'store', reason: data.reason }).subscribe((result) => {
            //console.log(result)
            if (result.code == 'OK') {
              //this.firestoreService.createOrUpdateDocument('orders', this.orderId,
              //  { requestStates: result.item.data.log, status: result.item.data.status, stimatedTime: 0 });

              this.requestStates$.next(result.item.data.log);
              this.rest.displayMsg('El pedido se cancelo correctamente.');
                /*setTimeout(() => {
                  this.firestoreService.deleteDocument('orders', this.orderData.id);
                  this.firestoreService.deleteDocument('orders-location', this.orderData.id);
                }, 1000);*/

                this.router.navigate(['/pending']);
              }else{
                /*if (result.msg) {
                  alert(msg);
                }*/
                //location.reload();
                this.router.navigate(['/pending']);
              }
            });
        }
      }
      ]
    });
    await alert.present();
  }

  async changeDriver() {
    console.log(this.driver);
    const loading = await this.loadingController.create({
      message: '',
    });
    await loading.present();
    this.rest.post('domicile/order-driver', { id: this.orderId, driver: this.driver }).subscribe((result) => {
      loading.dismiss();
      if (result.code == 'OK') {
        //this.firestoreService.createOrUpdateDocument('orders', this.orderId, { requestStates: result.item.data.log});
        //this.requestStates$.next(result.item.data.log);
        //this.orderData = result.item.data;
      }
      this.detectChanges();
    }, (err) => {
      loading.dismiss();
    });
  }

  getDrivers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.rest.post('domicile/drivers', { idenCargo: 'domiciliario' }).subscribe((result) => {
        resolve(result.items);
      });
    });
  }

  async accept() {
    this.updateOrderStatus('domicile/order-accept', this.orderId).then((result) => {
      if (result) {
        this.print();
      }
    });
  }
  async dispatch() {
    this.updateOrderStatus('domicile/order-dispatch', this.orderId);
  }

  async deliverInStore() {
    this.updateOrderStatus('domicile/order-deliver-store', this.orderId);
  }

  async readyOrder() {
    this.updateOrderStatus('domicile/order-ready', this.orderId);
  }

  async deliverToCustomer() {
    this.updateOrderStatus('domicile/order-deliver-customer', this.orderId);
  }
  updateOrderStatus(url, id) {
    return new Promise((resolve, reject) => {
      this.order.updateOrderStatus(url, id, this.stimatedTime).then((result: any) => {
        console.log(result)
        if(result){
          this.requestStates$.next(result.item.data.log);
          this.orderData = result.item.data;
        }
        resolve(result);
      })
    });
    /*return new Promise(async (resolve, reject) => {
      const loading = await this.loadingController.create({
              message: '',
      });
      await loading.present();
      this.rest.post(url, { id: id }).subscribe((result) => {
        loading.dismiss();
        if(result.code == 'OK'){
          this.firestoreService.createOrUpdateDocument('orders', this.orderId, 
                 { requestStates: result.item.data.log, status: result.item.data.status, stimatedTime: this.stimatedTime});
          this.requestStates$.next(result.item.data.log);
          this.orderData = result.item.data;
        }
        resolve(result);
        this.detectChanges();
      }, (err) => {
        loading.dismiss();
        resolve(null);
      });      
    });*/

  }
  async print() {
    console.log('print......');
    /*
    this.rest.postLocal('order/print', this.orderData).subscribe((response) => {
      console.log(response)
    });
    */
    return this.printService.print(this.orderData);
  }



  orderIsFinished() {
    return this.orderData.finished;
  }

  searchFirestoreOrder(id) {
    //this.firestoreSubscription = this.firestoreService.firestore.collection('orders', ref => ref.where('orderId', '==', id))
    // this.firestoreSubscription = this.firestoreService.firestore.collection('orders').doc(id)
    // .valueChanges()
    // .subscribe((values: any) => {
      //  console.log(values)
      //const ord = values[0];
      // console.log(ord);
    /*this.requestStates = ord.requestStates;

      if(ord.state.id == 10){
        //orden finalizada
        this.presentAlertConfirm();
      }*/

    /*if(ord.deliverer){
      this.deliverer = ord.deliverer;
    }*/
    /*if(!value || value.length == 0){
      this.firestoreService.createOrUpdateDocument('offers', id, { 'route_id': id, 'offers' : this.item.offers })
    }else{
      //if(value[0].offers && value[0].offers.length > 0){
        this.item.offers = value[0].offers;
        this.offers$.next(value[0].offers);
     // }
   }*/
   //});

   /*this.firestoreService.createOrUpdateDocument('orders-location', id, { lat: '0', lng: '0' })
   this.ubicationDeliverer = this.firestoreService.firestore.collection('orders-location').doc(id).valueChanges()
   .subscribe((values: any) => {
     console.log(values)
     this.deliverer = values;
     this.detectChanges();
   });*/
 }
 //stimatedTime
 mapClick($event) {
   console.log($event)
   this.firestoreService.createOrUpdateDocument('orders-location', this.orderId, { lat: $event.coords.lat + '', lng: $event.coords.lng + '' })
 }



 onResponse(event: any) {
   console.log('on response')
   console.log(event);
   // You can do anything.
 }

 async presentAlertConfirm() {
   const alert = await this.alertController.create({
     header: 'Confirm!',
     message: 'Su orden fue entregada',
     buttons: [
     {
       text: 'Cerrar',
       role: 'cancel',
       cssClass: 'secondary',
       handler: (blah) => {
         console.log('Confirm Cancel: blah');
       }
     }, {
       text: 'Calificar',
       handler: () => {
         console.log('Confirm Okay');
       }
     }
     ]
   });

   await alert.present();
 }
 detectChanges() {
   this.cd.detectChanges();
 }
 compareWith(obj1, obj2) {
   return obj1 && obj2 && obj1.id === obj2.id;
 }
 async addObservations(dataOrder){
   const modal = await this.modalController.create({
     component: ModalObservationsClientComponent,
     componentProps: { dataOrder }
   });
   modal.onWillDismiss().then((result) => {
     console.log("result", result);
     if(result && result.data){
       if(result.data.items){
         this.commentsClient = result.data.items;
       }
       this.detectChanges();
     }
   });
   await modal.present();    
 }
}

import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import { RestService } from './rest.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(
    public restService: RestService,
    public stateService: StateService
  ) {}

  async validateCode(email, code){
    return new Promise(async (resolve, reject) => {
      const loading = await this.restService.showLoading();
      this.restService.postAnonimus('domicile/validate-code', { email, code }).subscribe((response) => {
        loading.dismiss();
        if (response.code === 'OK' || response.success) {
          resolve(response);
        }else{
          resolve(false);
        }
      }, (err) => {
        loading.dismiss();
        reject(err);        
      })
    });    
  }
  async sendEmailCodeVerification(email, recaptcha, recoverPasswd=false) {
    return new Promise(async (resolve, reject) => {
      const loading = await this.restService.showLoading();
      this.restService.postAnonimus('domicile/send-code-verification', { email, recaptcha, recoverPasswd }).subscribe((response) => {
        loading.dismiss();
        resolve(response);

      }, (err) => {
        console.log(err)
        loading.dismiss();
        reject(err);
      })
    });

  }

  saveProfile(data){
    return this.restService.post('save-profile', data);
  }

  async signupUser(url, value) {
    return new Promise(async (resolve, reject) => {
      const loading = await this.restService.showLoading();
      this.restService.postAnonimus(url, value).subscribe((response) => {
        loading.dismiss();
        if (response.code === 'OK' || response.success) {
          resolve(response);
        }else{
          resolve(false);
        }
      }, (err) => {
        console.log(err)
        loading.dismiss();
        reject(err);        
      })
    });
  }

  loginUser(token, user, menu){
    this.stateService.authenticate(token, user, menu);
    /*if(this.hasMissingData(user)){
      //return false;
      throw new Error('MISSINDATA');

    }*/
    return true;
  }

  hasMissingData(user){
    if(user.missing){
      if(environment.profile_required){
        for(let miss of user.missing){
          if(environment.profile_required.indexOf(miss)>=0){
            return true;
          }
        }
      }
    }
    return false;
  }


  signinUser(email: string, password: string, recaptcha: string, app: string) {
    return new Promise((resolve, reject) => {
      this.restService.postAnonimus('system/auth/login-app', { user: email, password, recaptcha, group: app}).subscribe((result: any) => {
        if (result.success === 1 || result.code === 'OK') {
          this.stateService.authenticate(result.token, result.response.data, result.menu);
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }
  recover(email: string) {
    return new Promise((resolve, reject) => {
      this.restService.postAnonimus('/system/user/web-recover-passwd', {email}).subscribe((result: any) => {
        if (result.success === 1) {
          this.restService.displayMsg('Se envio un email con las instrucciones para recuperar tu contraseña.');
          resolve();
        } else {
          reject();
        }
      });
    });
  }
  changePasswd(params) {
    return new Promise((resolve, reject) => {
      this.restService.postAnonimus('/change-passwd', params).subscribe((result: any) => {
        if (result.success === 1) {
          this.restService.displayMsg('Su contraseña se cambio correctamente.');
          resolve(result);
        } else {
          reject(result);
        }
      });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.restService.post('auth/logout', {}).subscribe((response: any) => {
        // this.stateService.clear();
        resolve();
      });
    });
  }
  getAuthState() {
    return this.stateService.getAuthState();
  }

  getMenu(grupoId) {
    return new Promise((resolve, reject) => {
      this.restService.post('auth/menues-por-perfil', { grupo: { id: grupoId }}).subscribe((response) => {
        //this.stateService.setMenu(response.menues);
        resolve();
      });
    });
  }

  isAuthenticated() {
    // console.log('is authenticated?');
    let token = '';
    const auth = localStorage.getItem('auth');
    if (auth) {
      const authMap = JSON.parse(auth);
      token = authMap.token;
    }
    if (token !== '') {
      const helper = new JwtHelperService();
      if (helper.isTokenExpired(token)) {
        console.log('token expired');
        return false;
      }
    }

    return this.stateService.isAuthenticated;
    // return false;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { ShellModule } from '../shell/shell.module';

import { FieldSizePipe } from './pipes/field-size.pipe';
import { EscapeHtmlPipe } from './pipes/html.pipe';
import { MyCurrencyPipe } from './pipes/my-currency.pipe';
import { RatingInputComponent } from './rating-input/rating-input.component';


import {MatInputModule, MatExpansionModule, MatButtonModule, MatRadioModule, MatTableModule, MatCheckboxModule, MatFormFieldModule, MatDialogModule, MatTabsModule,
        MatSnackBarModule, MatPaginatorModule, MatSelectModule, MatAutocompleteModule, MatDatepickerModule} from '@angular/material';



@NgModule({
  declarations: [ShowHidePasswordComponent, FieldSizePipe, EscapeHtmlPipe, MyCurrencyPipe, RatingInputComponent],
  imports: [
    CommonModule, MatExpansionModule, TranslateModule, MatInputModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule,
    MatDialogModule, MatSnackBarModule, MatPaginatorModule, MatAutocompleteModule, MatTabsModule, MatRadioModule,
    MatDatepickerModule, NgxMaterialTimepickerModule, ScrollingModule, MatTableModule,
    ShellModule,
    IonicModule.forRoot()
  ],
  exports: [TranslateModule,MatExpansionModule, MatInputModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule,
    MatDialogModule, MatSnackBarModule, MatPaginatorModule, MatSelectModule, MatTabsModule, MatRadioModule,
    MatAutocompleteModule, MatDatepickerModule, NgxMaterialTimepickerModule, ScrollingModule, MatTableModule,
    ShowHidePasswordComponent ]
})
export class MaterialModule { }

import { Component, OnInit } from '@angular/core';
import { NavParams, MenuController, ModalController, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Platform } from '@ionic/angular';
import { auth } from 'firebase/app';
import { UserInfo} from 'firebase/app'
import { cfaSignIn, mapUserToUserInfo} from 'capacitor-firebase-auth';
import { AuthService } from './../../../services/auth.service';
import { RestService } from './../../../services/rest.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-signin',
  template: `
  <ion-header>
    <ion-toolbar color="primary"><ion-title class="pl-3">Selecciona la sucursal</ion-title></ion-toolbar>
  </ion-header>
  <ion-content>
      <ng-container *ngIf="(stores$|async) as stores" >       
              
              <ion-item *ngFor="let item of stores" (click)="selectStore(item)" detail>
                  <ion-label>{{item.nombre_sucursal}}</ion-label>
              </ion-item>
      </ng-container> 
  </ion-content>
  
  `
})
export class ModalStoreComponent implements OnInit {
  loginForm: FormGroup; 
  dataProfile: any;
  results: any;
  stores$ : BehaviorSubject<any> = new BehaviorSubject([]);


  constructor(public menuCtrl: MenuController, 
              private restService: RestService,
              public params: NavParams,
              private modalController: ModalController,
              private platform: Platform, private navParams: NavParams) { 

  }

  ngOnInit() {
    const city = this.params.get('city');

    this.restService.postAnonimus('domicile/available-stores', { city: city.id }).subscribe((result) => {
      this.stores$.next(result.items);
    });
  } 

  selectStore(item){
    this.modalController.dismiss( { store: item  });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { BusinessRoutingModule } from './routing.module';
import { NgxCaptchaModule } from 'ngx-captcha';
/**** components *****/
import { SigninComponent } from './components/signin/signin.component';
import { HomeComponent } from './components/home/home.component';
import { OrdersComponent } from './components/orders/orders.component';
import { DetailOrderComponent } from './components/detail-order/detail-order.component';
import { PendingOrdersComponent } from './components/pending-orders/pending-orders.component';
import { PendingOrdersForDispatchComponent } from './components/pending-orders-for-dispatch/pending-orders-for-dispatch.component';
import { OrdersDeliverStoreComponent } from './components/orders-deliver-store/orders-deliver-store.component';
import { ItemMenuOfferComponent } from './components/detail-order/item-menu-offer.component';
import { ProductsComponent } from './components/products/products.component';
import { BranchesComponent } from './components/branches/branches.component';
import { ActivityComponent } from './components/branches/activity.component';
import { ConfigComponent } from './components/branches/config.component';
import { ReportPaymentMethodComponent } from './components/report-payment-method/report-payment-method.component';
import { ReportOrderTrackComponent } from './components/report-order-track/report-order-track.component';
import { ReportSummaryComponent } from './components/report-summary/report-summary.component';

import { MomentModule } from 'ngx-moment';

import { ModalRecoverPasswdComponent } from './components/signin/modal-recover-passwd.component';
import { ModalPhoneComponent } from './components/signin/modal-phone.component';
import { ModalCityComponent } from './components/signin/modal-city.component';
import { ModalStoreComponent } from './components/signin/modal-store.component';
import { ModalUbicationComponent } from './components/signin/modal-ubication.component';
import { ModalObservationsClientComponent } from './components/detail-order/modal-observations-client.component';
import { MaterialModule } from './../material/material.module';

@NgModule({
  declarations: [
    SigninComponent, OrdersComponent, ModalPhoneComponent, HomeComponent, ModalStoreComponent, ModalCityComponent, ModalUbicationComponent, ModalRecoverPasswdComponent,
    PendingOrdersForDispatchComponent, PendingOrdersComponent, DetailOrderComponent, OrdersDeliverStoreComponent,
    ItemMenuOfferComponent, ProductsComponent, BranchesComponent, ModalObservationsClientComponent, ConfigComponent,
    ActivityComponent, ReportPaymentMethodComponent, ReportOrderTrackComponent, ReportSummaryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    ReactiveFormsModule,
    MaterialModule,
    IonicModule,
    AgmCoreModule,
    AgmDirectionModule,
    NgxCaptchaModule,
    BusinessRoutingModule
  ],
  entryComponents: [ ModalUbicationComponent, ModalStoreComponent, ModalPhoneComponent, ModalCityComponent, ModalRecoverPasswdComponent, ModalObservationsClientComponent],
  exports: [ ModalStoreComponent, ModalCityComponent ]
})
export class BusinessModule { }

import { Component, OnInit, OnDestroy, Input, ViewChild, NgZone, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { Geolocation } = Plugins;
import { ModalController, NavParams } from '@ionic/angular';
import { MapService } from './../../../services/map.service';
import { RestService } from './../../../services/rest.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';

declare var google: any;
@Component({
  selector: 'app-modal-ubication',
  template: `
<div>
<div style="position: fixed; z-index: 150; top: 10px; width: 100%; height:100%; background-color: rgba(30,30,30,0.5)" *ngIf="selected">
 <ion-list class="inputs-list" lines="full" >
               <ion-item class="input-item">
                  Dirección de entrega:
                </ion-item>
                <ion-item class="input-item">
               
                    <ion-input [(ngModel)] = "address"
                        clearInput autocapitalize="off" ></ion-input>
                </ion-item>

                <div class="text-center pt-2 pb-2">
                <button mat-raised-button color="accent" (click)="updatePosition()"><i class="far fa-hand-point-up"></i>&nbsp; {{'Modificar en mapa'|translate}}</button>
                  <button  mat-raised-button color="primary" [disabled]="!address"   (click)="closeAndSave()"><i class="far fa-save"></i>&nbsp;{{'Aceptar'|translate}}</button>
                </div>
  </ion-list>
</div>
    <div *ngIf="!selected" style="position: fixed; z-index: 150; top: 10px; width: 100%">
        <div style="background-color: rgba(255,255,255,0.8);padding: 5px;">
            <h4>{{title}}</h4>
            <a (click)="cancel()" style="position: absolute; top: 10px; right:15px"><i class="fas fa-times"></i></a>
            <div style="display:flex;flex-direction: column">
            <!--div>
                <input type="text" class="form-control" 
                       (keydown.enter)="$event.preventDefault()" 
                       placeholder="Dirección de entrega domicilio" 
                       autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
            </div-->          
            </div>
            <div style="margin-top: 5px;text-align:center">
                  <button mat-raised-button color="accent" (click)="changeLocation()"><i class="far fa-hand-point-up"></i>&nbsp; {{'Modificar en mapa'|translate}}</button>
                  <!--button style="margin-left: 5px;" mat-raised-button color="accent"   (click)="findMe()"><i class="fas fa-globe"></i>&nbsp;{{'Usar GPS'|translate}}</button-->
                  <button style="margin-left: 5px;" mat-raised-button color="primary"   (click)="selectLocation()"><i class="far fa-save"></i>&nbsp;{{'Seleccionar Ubicación'|translate}}</button>
            </div>
        </div>
      </div>
      <div style="width: 100%">
        <agm-map (mapReady)="onMapReady($event)" [latitude]="center.lat" [longitude]="center.lng" [zoom]="zoom" (centerChange)="centerOfMapChange($event)" [draggable]="false">
          <div *ngIf="!allowEdit">
            <agm-marker *ngIf="newLocation" [latitude]="newLocation.lat" [longitude]="newLocation.lng"></agm-marker>

         
          </div>          

          <agm-polygon *ngFor="let item of subsidiaries" [paths]="item"> </agm-polygon>
        </agm-map>

      <div *ngIf="allowEdit" class="centerMarker"></div>
      </div>
  </div>
  `,
  styles: [`
  agm-map {
    height: 100vh;
  }
  .centerMarker{
    position:absolute;
    background:url(http://maps.gstatic.com/mapfiles/markers2/marker.png) no-repeat;
    top:50%;left:50%;
    z-index:1;
    margin-left:-10px;
    margin-top:-34px;
    /*size of the image*/
    height:34px;
    width:20px;
    cursor:pointer;
  }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalUbicationComponent implements OnInit, OnDestroy {
  center = {lat: 3.488590, lng: -76.528900};
  address = '';
  city = '';
  zoom = 14;
  info = {};
  allowEdit = false;
  newLocation = null;
  positionCenter = null;
  place = null;
  gps = false;
  searched = false;
  geoCoder: any;
  subsidiary: any;
  hasCoverage = false;
  selected = false;
  title = 'Selección de Ubicación';
  @ViewChild('search', {static: true})
  public searchElementRef: ElementRef;

  @Input() params: any;
  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private cd: ChangeDetectorRef, private mapService: MapService,
    private modalCtrl: ModalController, private navParams: NavParams, public rest: RestService) { }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.center = {lat: 3.488590, lng: -76.528900};
    this.city = this.navParams.get('city');
    this.address = this.navParams.get('place');
    if (!this.address){
      //this.address = this.city +' ' + this.navParams.get('place');
      if(this.city){
        this.address = this.city;
      }
    }    

    const title = this.navParams.get('title');
    if(title){
      this.title = title;
    }
    const point = this.navParams.get('point');
    if(point){
      this.center = point;
      this.newLocation = point;
      this.positionCenter = point;
      this.searched = true;
      //this.initAutocomplete();
    }else{
      Geolocation.getCurrentPosition().then((position) => {
        this.center = { lat: position.coords.latitude, lng: position.coords.longitude  };
        console.log(this.center)
        //this.newLocation = { lat: position.coords.latitude, lng: position.coords.longitude  };
        //this.positionCenter = Object.assign({}, this.newLocation);
        //this.initAutocomplete();
        setTimeout(() => {
          this.findMe();
        }, 250);
      });      
    }
    this.getNearbySubsidiaries();
  }
  subsidiaries = [];

  getNearbySubsidiaries(){
     this.rest.post('domicile/nearby-subsidiaries', this.positionCenter).subscribe((result) => {
      if(result.items && result.items.length > 0){
        if(result.items.length == 1){
          this.hasCoverage = true;
          this.subsidiary = result.items[0];
          this.rest.displayMsg('Sucursal: ' + result.items[0].nombre);
        }else{
          for(const s of result.items){
            if(s.geo_cobertura){
              this.subsidiaries.push(this.extractPolygon(s.geo_cobertura));
            }
          }
        }
        this.cd.detectChanges();
      }else{
        this.rest.post('domicile/nearest-branch', this.positionCenter).subscribe((result) => {
          if(result.items && result.items.length > 0){
            this.subsidiary = result.items[0];
            this.rest.displayMsg('Aprox: : ' + result.items[0].nombre);
          }
        });
      }
    });
  }
  extractPolygon(pointsStr: string){
    let strClean = pointsStr.replace(/\(/g, '');
    strClean = strClean.replace(/\)/g, '');
    const data = strClean.split(',')
 
    const points = [];
    for(let i = 0; i < (data.length - 1); i+=2){
      points.push({ lat: parseFloat(data[i]), lng: parseFloat(data[i+1])});
    }
    return points;
  }
// avenida 6 norte 42n30
  initAutocomplete(){
    this.mapsAPILoader.load().then(() => {
      //this.setCurrentLocation();
      //this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        //componentRestrictions: {country: 'co'}
        types: ["address"]
      });
      const circle = new google.maps.Circle(
          {center: this.center, radius: 20000});
       autocomplete.setBounds(circle.getBounds());

       autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result google.maps.places.PlaceResult
          let place: any  = autocomplete.getPlace();
          console.log(place)
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.place = place;
     
          const position = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
          this.newLocation = position;
          this.positionCenter = Object.assign({}, this.newLocation);

          this.center = Object.assign({}, this.newLocation);
          this.cd.detectChanges();
        });
      });
    });
  }

  async findMe() {
    this.newLocation = await this.getCurrentPosition();
    this.positionCenter = Object.assign({}, this.newLocation);
    this.allowEdit = false;
    this.gps = true;
    this.cd.detectChanges();
  }

  searchAddress(){
    if(this.address == ''){
      return;
    }
    let search =  this.city + ', ' + this.address;
    if(!this.city){
      search = this.address;
    }
    this.searchUbication(search).subscribe(async (loc) => {
      this.newLocation = loc;
      this.center = loc;
      this.positionCenter = loc;
      this.searched = true;
      this.cd.detectChanges();
    });
  }

  searchUbication(address: string) {
    return this.mapService.getGeoLocation(address);
  }

  changeLocation() {
    this.allowEdit = true;
    this.gps = false;
    this.cd.detectChanges();
  }

  cancel(){
    this.modalCtrl.dismiss(null);
  }
  selectLocation() {   
    this.mapService.getAddress(this.positionCenter.lat, this.positionCenter.lng).subscribe((result) => {
      //console.log(result[0])
      this.info = this.mapService.getInfoFromPlace(result[0]);
      this.address = this.info['address'];
      this.selected = true;
      console.log(this.info);
      this.cd.detectChanges();
    })
    //this.modalCtrl.dismiss({ point: this.positionCenter, coverage: this.hasCoverage, subsidiary: this.subsidiary });
  }

  closeAndSave(){
    this.info['address'] = this.address;
    this.modalCtrl.dismiss(this.info);

  }

  updatePosition(){
    this.selected = false;
    this.cd.detectChanges();
  }

  async getCurrentPosition(){
    const position = await Geolocation.getCurrentPosition();
    return { lat: position.coords.latitude, lng: position.coords.longitude  };
  }


  lastTimeChange: any = 0;
  centerOfMapChange(event) {
    if (this.allowEdit) {
       this.positionCenter = { lat: event.lat, lng: event.lng };
       const time: any = new Date();
       if((time - this.lastTimeChange) > 500) {
         //this.mapService.geocodeLocationReverse(event.lat, event.lng).subscribe((result) => {
         //console.log(result)
           //this.getNearbySubsidiaries();
         //});
       }
       this.lastTimeChange = time;

    }
  }

  onMapReady(map) {
    // this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        draggable: false,
        editable: false
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);
  }
}
import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Platform } from '@ionic/angular';
import { auth } from 'firebase/app';
import { UserInfo} from 'firebase/app'
import { cfaSignIn, cfaSignOut, mapUserToUserInfo, cfaSignInPhoneOnCodeSent, cfaSignInPhoneOnCodeReceived} from 'capacitor-firebase-auth';
import { AuthService } from './../../../services/auth.service';
import { StateService } from './../../../services/state.service';
import { MapService } from './../../../services/map.service';
import { ModalRecoverPasswdComponent } from './modal-recover-passwd.component';
import { ModalPhoneComponent } from './modal-phone.component';
import { ModalCityComponent } from './modal-city.component';
import { ModalStoreComponent } from './modal-store.component';
import { ModalUbicationComponent } from './modal-ubication.component';
import { environment } from './../../../../environments/environment';
import { FirestoreService } from './../../../services/firestore.service';
import * as firebase from 'firebase/app';

declare var google: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  registerForm: FormGroup;
  login: string = "signin";
  indicativo: string = "+57";
  position = 'fixed';
  dataProfile = {};
  environment = environment;
  forceFullRegistration = true;
  app = environment.app; //'customer';
  subsidiary = null;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ],
    'passwd': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 5 characters long.' }
    ]
  };

  constructor(public menuCtrl: MenuController, private modalController: ModalController,
    private mapService: MapService,
    private platform: Platform,
    private router: Router,
    private firestoreService: FirestoreService,
    private state: StateService,
    private authService: AuthService,
    public afAuth: AngularFireAuth) { }
//delete from sis_usuario where email in ('geyffer@gmail.com');
//delete from gen_otras_ubicaciones_persona where gen_persona_id in ( select id from gen_persona where email in ('geyffer@gmail.com') );
//delete from gen_persona where email in ('geyffer@gmail.com');

  async ngOnInit() {
    
    this.init();
   // this.completeBasicProfile();
   //this.loginOrCeateUser();
    // this.mapService.getNearbySubsidiaries(this.modalController, ModalUbicationComponent);
    //this.mapService.getAddressUbication(this.modalController, ModalUbicationComponent);
    // this.completeBasicProfile();
    // this.dataProfile['email'] = 'geyffer@gmail.com';
    // this.loginOrCeateUser();
    // this.loginOrCeateUser();
    //this.loginUserAppServer('facebook', this.dataProfile['uuid_firebase']);
    let token = this.state.getToken();
    if(token) {
      if(this.state.storeInstalled()){
          this.syncroStatus('online');
          this.navigateHome();
       }
    }
  }


  async init() {
    let dataTmp = localStorage.getItem('tmp');
    if(dataTmp){
      this.dataProfile = JSON.parse(dataTmp);
    }
    this.menuCtrl.enable(false);

    this.loginForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      'passwd': new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required
      ]))
    });
  }

  loginEmail(){
    const value = this.loginForm.value;
    console.log('login.....');
    this.authService.signinUser(value.email, value.passwd, null, 'cajero').then((result) => {
      console.log(result)
      if(result){
        if(this.state.storeInstalled()){
          this.syncroStatus('online');
          this.navigateHome();
        }else{
          this.setStore();
        }
      }else{
        alert('error')
      }
    }).catch((err) => {
      this.authService.restService.displayError('El correo y/o la contraseña no son validos');
    });
  }
  syncroStatus(status){
    this.subsidiary = this.state.getSubsidiary();
    localStorage.setItem('status', status);
    const lh = firebase.firestore.FieldValue.serverTimestamp();
    this.firestoreService.createOrUpdateDocument('stores_activity', this.subsidiary.id+"", { last_online: lh, status: status});

  }
  async setStore(){

    const modal = await this.modalController.create({
          component: ModalCityComponent
        });
        modal.onWillDismiss().then((result) => {
          if(result && result.data && result.data.city){
            this.openStoreModal(result.data.city);
            
          }
        });
        await modal.present();
  }
  async openStoreModal(city){
        const modal = await this.modalController.create({
          component: ModalStoreComponent,
          componentProps: { city }
        });
        modal.onWillDismiss().then((result) => {
          if(result && result.data && result.data.store){
            this.state.setSubsidiary(result.data.store);
            this.syncroStatus('online');
            this.navigateHome();
          }
        });
        await modal.present();
  }

  async recoverPasswd(){
    const modal = await this.modalController.create({
          component: ModalRecoverPasswdComponent
        });
        modal.onWillDismiss().then((result) => {
        });
        await modal.present();
  }

  navigateHome(){
    console.log('to home');
    //this.router.navigateByUrl('/home');
    document.location.href = '/home'
  }



 

  logout(){
    //  this.state.clear();
    if(this.platform.is('mobileweb')|| this.platform.is('desktop')){
       this.afAuth.auth.signOut();
    }else{
      cfaSignOut().subscribe();
    }
    
   }
 
}


import { Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'app-item-menu-offer',
  template: `  
              <ng-container [ngSwitch]="getPromo(item)">
               <ng-container *ngSwitchCase="'disc'" >
                 <span *ngIf="hasPromo(item)" class="price-sub"> {{item.precio_venta|currency}}</span>
                  
                  <span *ngIf="hasPromo(item)" class="price-promo"> {{calcDiscountPrice(item)|currency}}</span>
                  <span class="promo-item ml-2"><ion-badge color="primary"> - {{calcDiscountPercentage(item)}}%</ion-badge></span>
               </ng-container>
               <ng-container *ngSwitchCase="'2x1'" >
                 <span class="price"> {{item.precio_venta|currency}}</span>
                 <div class="promo-item"><ion-badge color="primary">2 x 1</ion-badge></div>
               </ng-container>

               <ng-container *ngSwitchDefault >
                 <span class="price"> {{item.precio_venta|currency}}</span>
               </ng-container>
            </ng-container>
  `,
  styles: [`
    
    .price{
      font-size: 0.85rem;
      text-decoration: none;
    }
    .store-product .store-product-info span.store-product-price .price {
        width: auto;
    }

  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemMenuOfferComponent  {

  @Input() item: any;

  constructor() { 

  }

  calcDiscountPrice(item){
    return item.product.precio_venta - ( item.product.precio_venta * (this.calcDiscountPercentage(item) /100));
  }

  calcDiscountPercentage(item){
    return item.offer.porcentaje_descuento;
  }

  getPromo(item){
    console.log(this.item)
    if(item.offer){
      if(item.offer.fact_tipo_descuento_id == 2){
        return '2x1';
      }
      if(item.offer.fact_tipo_descuento_id == 5){
        return 'disc';
      }
    }

    return ''; //this.order.getTypePromotion(item);
  }
  hasPromo(item){
    if(item.offer){
      return true;
    }
    return false;
  }
  
}

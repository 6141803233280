import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(public firestore: AngularFirestore) { 

    /*const itemDoc = this.firestore.doc<any>('coffeeOrders/MeZaUD7t7LBLjIa2isKy');
    itemDoc.valueChanges().subscribe((value) => {
        console.log(value)
    })*/
   /* this.firestore.collection('solicitudes', ref => ref.where('id', '==', 1)).valueChanges().subscribe((value) => {
        console.log(value)
    })*/
  }

  createOrUpdateDocument(collection: string, id: string, data: any) {
    return new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection(collection)
            .doc( id )
            .set(data)
            .then(res => { },
            err => {
              console.log(err);
              reject(err)}
              );
    });
  }

  createDocument(collection: string,  data: any) {
    return new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection(collection)
            .add(data)
            .then(res => { console.log(res)}, err => reject(err));
    });
  }
  getDocuments(collection: string) { 
    return 
     this.firestore.collection(collection).snapshotChanges();
  }
  getAllDocuments(collection){
    let ref = this.firestore.collection(collection);
    return ref.get();
  }
  updateDocument(collection: string, id: string, data: any) {
    return
        this.firestore
        .collection(collection)
        .doc(id)
        .set(data);
 }
 deleteDocument(collection: string, id: any) {
   console.log(collection, id)
    return
        this.firestore
        .collection(collection)
        .doc(id)
        .delete();
 }
}

import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { RestService } from './../../../services/rest.service';
import { StateService } from './../../../services/state.service';
// import { PrintService } from './../../../services/print.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-report-order-track',
  templateUrl: './report-order-track.component.html',
  styleUrls: ['./report-order-track.component.scss'],
})
export class ReportOrderTrackComponent implements OnInit {

  // dataSource$ = new BehaviorSubject([]);
  orders: any;
  title = 'Reporte Seguimiento de Ordenes';
  mostrarDatos = false;
  routeSubscription = null;
  form: FormGroup;

  constructor(private rest: RestService, private state: StateService, protected route: ActivatedRoute) { }

  ngOnInit() {
    this.routeSubscription = this.route.url.subscribe(async (url) => {
      this.form = new FormGroup({
        'fecha_inicio': new FormControl('', Validators.required),
        'fecha_fin': new FormControl('', Validators.required),
        'order_status': new FormControl('')
      });
    });
  }

  ngOnDestroy() {
    for (const property in this) {
      if (this[property] && typeof this[property]['unsubscribe'] !== 'undefined') {
        this[property]['unsubscribe']();
      }
    }
  }

  search() {
    const subsidiary: any = this.state.getSubsidiary();
    this.rest.post('domicile/report-order-track', {data_form: this.form.value}).subscribe((response) => {
      // console.log(response.items);
      if (response.items) {
        this.mostrarDatos = true;
        this.orders = response.items;
      }
    });
  }

  print() {
	  var div = document.getElementById('resultSearch');
	  var ventimp = window.open(' ', 'popimpr');
	  ventimp.document.write(div.innerHTML);
	  ventimp.document.close();
	  ventimp.print( );
	  // ventimp.close();
  }

  isValid() {
    return this.form.valid && this.form.dirty;
  }

}

import { Component, OnInit, OnDestroy, Input, ViewChild, NgZone, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { RestService } from '../../../services/rest.service';

declare var google: any;
@Component({
  selector: 'app-modal-ubication',
  template: `

  <div>

  <!--<ion-list class="inputs-register" lines="full">-->
  <ion-list class="mt-3 mb-3">        
    <ion-item >
      <ion-grid>
        <ion-row *ngIf="dataOrder.customerName">
            <ion-col class="text-left" size="6">
                <span style="font-weight: 500">Cliente</span>
            </ion-col>
            <ion-col class="cart-col-total">
                {{dataOrder.customerName}}
            </ion-col>
        </ion-row>
        <ion-row *ngIf="dataOrder.customerPhone">
            <ion-col class="text-left" size="6">
                <span style="font-weight: 500">Celular</span>
            </ion-col>
            <ion-col class="cart-col-total">
                {{dataOrder.customerPhone}}
            </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <form [formGroup]="form" autocomplete="off">
      <ion-item class="input-item mt-3 mb-2">
                <ion-label position="floating">Observaciones</ion-label>
                <ion-textarea type="text" formControlName="observaciones" ></ion-textarea>
      </ion-item>
    </form>
    <div style="margin-top: 15px; margin-bottom: 45px; text-align:center">
        <ion-button (click)="cancel()" color="secondary"  >{{'Cancel'|translate}}</ion-button>
        <ion-button (click)="saveObservation()" [disabled]="!isValid()" color="primary"  >Guardar</ion-button>
        
    </div>
  </ion-list>

  </div>

  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalObservationsClientComponent implements OnInit, OnDestroy {
  form: FormGroup;
  dataOrder: any;

  // @Input() params: any;
  constructor(private ngZone: NgZone, private cd: ChangeDetectorRef,
    private modalCtrl: ModalController, private navParams: NavParams, public rest: RestService) { }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.dataOrder = this.navParams.get('dataOrder');
    // console.log(this.dataOrder);
    this.form = new FormGroup({
      'cliente_id': new FormControl(this.dataOrder.customerId),
      'observaciones': new FormControl('', Validators.required)
    });
  }

  saveObservation() {
    // console.log("Guardando las observaciones...");
    this.rest.post('domicile/save-observation-client', this.form.value).subscribe((response) => {
      // console.log(response);
      this.close(response);
    });    
  }

  isValid() {
    return this.form.valid && this.form.dirty;
  }

  close(dataResponse) {
    this.modalCtrl.dismiss(dataResponse);
  }

  cancel() {
    this.modalCtrl.dismiss(null);
  }

}
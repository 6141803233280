import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RestService } from './services/rest.service';
import { StateService } from './services/state.service';
import { FirestoreService } from './services/firestore.service';
import { OrderService } from './services/order.service';
import { PrintService } from './services/print.service';
import { MapService } from './services/map.service';
import { Platform } from '@ionic/angular';
import { Plugins, StatusBarStyle } from '@capacitor/core';
const { SplashScreen, StatusBar } = Plugins;
import { MapsAPILoader } from '@agm/core';
import { ToastController } from '@ionic/angular';
import { MenuController, ModalController } from '@ionic/angular';
import { ModalCityComponent } from './business/components/signin/modal-city.component';
import { ModalStoreComponent } from './business/components/signin/modal-store.component';
import * as moment from 'moment';
declare var google: any;
import * as firebase from 'firebase/app';
import { SwUpdate } from '@angular/service-worker/';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {


  isStatusBarLight = true;
  generalConfigCountry: any = {};
  public appPages = [
  { title: 'Pedidos por recibir', subtitle: '', url: '/pending', icon: 'list-box' },
  { title: 'Pedido por despachar', subtitle: '', url: '/dispatch', icon: 'list-box' },
  { title: 'Pedidos para recoger', subtitle: '', url: '/orders-store', icon: 'list-box' },
  { title: 'Historial de Pedidos', subtitle: '', url: '/orders', icon: 'list-box' },
  { title: 'Reporte Formas de Pago', subtitle: '', url: '/report-payment-method', icon: 'list-box' },
  { title: 'Reporte Seguimiento de Ordenes', subtitle: '', url: '/report-order-track', icon: 'list-box' },
  { title: 'Productos', subtitle: '', url: '/products', icon: 'list-box' },
  { title: 'Sucursales', subtitle: '', url: '/branches', icon: 'list-box' },
  //{ title: 'Configuración', subtitle: '', url: '/config-branch', icon: 'list-box' }
  ];
  infoUser = null;
  subsidiary = null;
  interval = null;
  profile = null;
  toast = null;
  firestoreSubscription = null;
  updateWeb = false;
  volume = 1;
  version = environment.version;

  constructor(
    private platform: Platform,
    private state: StateService,
    private rest: RestService,
    public toastController: ToastController,
    private firestoreService: FirestoreService,
    private swUpdate: SwUpdate,
    private map: MapService,
    private router: Router,
    private mapsApiLoader: MapsAPILoader,
    private menu: MenuController,
    public translate: TranslateService,
    public orderService: OrderService,
    private modalController: ModalController, 
    private printService: PrintService
    ) {

    translate.setDefaultLang('es_CO');
    translate.use('es_CO');

    // asegura que se cargue las librerias de maps antes de realizar cualquier otra operación
    //this.mapsApiLoader.load().then(() => {
      let alive = localStorage.getItem('alive');
      if (!alive) {
        localStorage.setItem('alive', '' + 120000);
        alive = localStorage.getItem('alive');
      }
      this.initializeApp();
      this.state.auth$.subscribe((st) => {

        this.subsidiary = this.state.getSubsidiary();
        //console.log('auth change...', st)
        if (st && st.authenticated) {
          this.syncroStatus(this.getStatus());

          this.profile = this.state.getData();
          this.infoUser = st.data;
          if (!this.interval) {
            this.interval = setInterval(() => {
              console.log('Send request ' + alive)
              this.syncroStatus(this.getStatus());
            }, parseInt(alive));
          }
          this.state.store$.subscribe((store) => {
            // console.log(store)
            if (store) {
              this.firestoreSubscription = this.firestoreService.firestore.collection('store-orders-create').doc('store-' + store.id).valueChanges()
              .subscribe((values: any) => {
                this.consultarOrdenesPendientes();
              });
              //console.log(this.firestoreSubscription)
            }
          });
          // this.firestoreSubscription();
          //console.log(this.firestoreSubscription);

        } else {
          clearInterval(this.interval);
          this.interval = null;
          if(this.firestoreSubscription){
            this.firestoreSubscription.unsubscribe();
            this.firestoreSubscription = null;
          }
        }
      });
    }
    syncroStatus(status) {
      const lh = firebase.firestore.FieldValue.serverTimestamp();
      this.firestoreService.createOrUpdateDocument('stores_activity', this.subsidiary.id + "",
        { last_online: lh, status: status, msg: 'pong' });
    }
    writeVerion() {
      const lh = firebase.firestore.FieldValue.serverTimestamp();
     /* this.rest.getRemote(' http://ip-api.com/json' ).subscribe((response) =>{
        console.log(response)
        this.firestoreService.createOrUpdateDocument('store_version', this.subsidiary.nombre + "",
          { version: 1, fecha: new Date(), response });
      }, (er) => {
        console.log(er)*/
        this.firestoreService.createOrUpdateDocument('store_version', this.subsidiary.nombre + "",
          { version: this.version, fecha: new Date() });

        // })

      }
      getStatus() {
        let status = localStorage.getItem('status');
        if (!status) {
          localStorage.setItem('status', 'online');
          status = 'online';
        }
        return status;
      }
      openMenu() {
        this.menu.enable(true, 'first');
        this.menu.open();
      }
      verSucursales() {
        this.router.navigate(['/activity'])
      }

      canEdit() {
        if (this.profile) {
          const profile = this.profile.grupo_identificador;
          if (['super', 'admin_domicilios'].indexOf(profile) >= 0) {
            return true;
          }
        }
        return false;
      }

      initializeApp() {
        SplashScreen.show({
          autoHide: false
        });

        const configCountries = {
          "CO": { "name": "Colombia", "phone_code": 57, "low_demand_schedules": [[ "14:00", "18:00"]],  "low_estimated_delivery_time": 30,
          "default_estimated_delivery_time": 40, "wapi": "pub_test_qQ1juI6GIgml4g6C32VD5InaErk3yYdq", "wclose": 1,
          "maximum_waiting_time": 30 }
        };
        this.state.persist('countries', configCountries);
        this.generalConfigCountry = configCountries['CO'];
        // carga los paises disponibles para la app
     /* this.rest.getAnonimus('assets/info-app.json', null).subscribe((response) => {
        console.log(response)
        this.state.persist('countries', response);
        this.generalConfigCountry = response['CO'];
        //console.log(this.generalConfigCountry);
        //this.consultarOrdenesPendientes();
      });*/

      //this.rest.post('/domicile/info-app', null).subscribe((response) => {
        //    console.log(response)
        //this.state.persist('appconfig', response.info);
        //   });


        this.platform.ready().then(async () => {

          setTimeout(() =>{
            let volume = localStorage.getItem('volume');
            if(!volume){
              this.volume = 1.0;
            }else{
              this.volume = parseFloat(volume)
            }
            this.setVolume();
          }, 1000)
     /* setTimeout( () => {
        alert('Sistema listo')
      }, 800);*/
      //this.reproducirSonidoConstante();
      this.writeVerion();
      this.reloadApp();
      this.swUpdate.available.subscribe(event => {
        // if (confirm('New version is available, do you want to reload to update?')) {
          console.log('New version is available, do you want to reload to update?')
          this.swUpdate.activateUpdate().then(() => this.reloadApp());
        });
      //});


      SplashScreen.hide();

    });
      }


      reloadApp(){
        let lu: any = localStorage.getItem('last_update');
        if(!lu) {
          localStorage.setItem('last_update', (new Date()).getTime()+'');
          document.location.reload();
          
        }else{
          console.log(lu, (new Date()).getTime(), ((new Date()).getTime() - lu)/(10 * 60 *1000))
          if(((new Date()).getTime() - lu)/(60*1000) > 1) {
            localStorage.setItem('last_update', (new Date()).getTime()+'');
            setTimeout(() => {
              document.location.reload();
            }, 250)

          }
        }
        //
        //
      }

      consultarOrdenesPendientes() {
        console.log('consultando ordenes pendientes...')

        //this.orderService.calculateStimatedTime(this.generalConfigCountry['low_demand_schedules'], this.generalConfigCountry['low_estimated_delivery_time'], this.generalConfigCountry['default_estimated_delivery_time']);
        this.subsidiary = this.state.getSubsidiary();
        this.rest.post('domicile/orders', { status: 'pending', 'subsidiary': this.subsidiary.id, type: 'store' }).subscribe((response) => {
          //console.log(response)
          if (response.items && response.items.length > 0) {
            setTimeout(()=> {
              this.reproducirSonidoConstante();
            }, 1);

            //alert('Ordenes pendientes por recibir');
            console.log('Ordenes pendientes: ', response.items.length)
            if (this.myInterval) {
              clearInterval(this.myInterval);
            }
            /*setTimeout(()=> {
              //this.reproducirSonidoAviso();
              if (!this.generalConfigCountry) {
                this.generalConfigCountry = { maximum_waiting_time: 30, low_demand_schedules: null, low_estimated_delivery_time: 30, default_estimated_delivery_time: 40 };
              }
              //console.log(this.generalConfigCountry)
              for (const order of response.items) {
                //console.log(order)
                if (this.pedidoHaceMasDe(order.orderDate, (this.generalConfigCountry['maximum_waiting_time'] / 60))) {
                  //console.log('...................')
                  let stimated = this.orderService.calculateStimatedTime(this.generalConfigCountry['low_demand_schedules'], this.generalConfigCountry['low_estimated_delivery_time'], this.generalConfigCountry['default_estimated_delivery_time']);
                  //console.log('...................', stimated)
                  this.orderService.updateOrderStatus('domicile/order-accept', order.id, stimated).then((result: any) => {
                    setTimeout(()=>{
                      this.printService.print(result.item.data);
                    }, 3000)
         
                  })
                }
              }
            }, 500);*/
            let seconds = 30;
            if (this.generalConfigCountry && this.generalConfigCountry['maximum_waiting_time']) {
              seconds = this.generalConfigCountry['maximum_waiting_time'];
            }
            this.presentToastWithOptions(response.items.length);
            setTimeout(() => {
              this.consultarOrdenesPendientes();
            }, 1000 * seconds)
          }
          //this.dataSource = response.items;
        });
      }
      pedidoHaceMasDe(orderDate, minutes) {

        const horaPedido = moment(orderDate);
        const now = moment();
        console.log(moment.duration(now.diff(horaPedido)).asMinutes());
        if (moment.duration(now.diff(horaPedido)).asMinutes() > minutes) {
          //console.log('jijijijiji')
          return true;
        }
        return false;
      }

      setVolume(){
        var audio: any = document.getElementById("audio");
        audio.volume = this.volume;
        console.log(this.volume)
      }

      addVol() {
        var audio: any = document.getElementById("audio");

        if(this.volume < 1){
          this.volume += 0.1;
        }
        localStorage.setItem('volume', this.volume+'');
        audio.volume = this.volume;
        //audio.muted = false;
        //audio.play();
      }
      subVol() {
        
        if(this.volume > 0.1){
          this.volume -= 0.1;
        }
        if(this.volume <= 0){
          this.volume = 0;
        }
        localStorage.setItem('volume', this.volume+'');
        var audio: any = document.getElementById("audio");
        audio.volume = this.volume;
        //audio.muted = false;
        //audio.play();
      }

      reproducirSonidoAviso() {
        var audio: any = document.getElementById("audio");
        audio.muted = false;
        audio.play();
      }
      myInterval = null;
      reproducirSonidoConstante() {
        this.myInterval = setInterval(() => {
          this.reproducirSonidoAviso();
        }, 1000);
      }

      async presentToastWithOptions(pendigOrders) {
        //this.reproducirSonidoConstante();
        if(this.toast) {
          return;
        }
        try{
          this.toast = await this.toastController.create({
            header: 'Atención',
            message: 'Hay ' + pendigOrders + ' pedidos por recibir.',
            position: 'top',
            buttons: [
            {
              side: 'start',
              icon: 'alarm',
              text: 'Nuevos Pedidos',
              handler: () => {
                console.log('Favorite clicked');
                if (this.myInterval) {
                  clearInterval(this.myInterval);
                }
                this.toast = null;
                this.router.navigate(['/home/' + (new Date()).getTime()]);
              }
            }, {
              text: 'Revisar',
              //role: 'cancel',
              handler: () => {
                //console.log('Cancel clicked');
                if (this.myInterval) {
                  clearInterval(this.myInterval);
                }
                this.toast = null;
                this.router.navigate(['/home/' + (new Date()).getTime()]);
              }
            }
            ]
          });
          this.toast.present();
        }catch(e) {
          console.log(e)
        }
      }
      changeStatusBar() {
        StatusBar.setStyle({
          style: this.isStatusBarLight ? StatusBarStyle.Dark : StatusBarStyle.Light
        });
        this.isStatusBarLight = !this.isStatusBarLight;
      }

      hideStatusBar() {
        StatusBar.hide();
      }
      logout() {
        clearInterval(this.interval);
        this.interval = null;
        localStorage.setItem('status', 'offline');
        this.syncroStatus('offline');
        this.state.clear();
        this.profile = null;
        if(this.firestoreSubscription){
          this.firestoreSubscription.unsubscribe();
          this.firestoreSubscription = null;
        }
        this.router.navigate(['/signin']);
      }

      showStatusBar() {
        StatusBar.show();
      }

      async setStore() {
        const modal = await this.modalController.create({
          component: ModalCityComponent
        });
        modal.onWillDismiss().then((result) => {
          if (result && result.data && result.data.city) {
            this.openStoreModal(result.data.city);

          }
        });
        await modal.present();
      }
      async openStoreModal(city) {
        const modal = await this.modalController.create({
          component: ModalStoreComponent,
          componentProps: { city }
        });
        modal.onWillDismiss().then((result) => {
          if (result && result.data && result.data.store) {
            this.state.setSubsidiary(result.data.store);
            this.subsidiary = this.state.getSubsidiary();
            this.syncroStatus('online');
            this.navigateHome();
          }
        });
        await modal.present();
      }

      navigateHome() {
        console.log('to home');
        this.router.navigateByUrl('/home');
      }

    }

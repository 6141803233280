// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


//baseUrl = 'https://test.mrbross.com';
//baseUrl = 'http://dev-erpv3.source-solutions.co:8103';
let baseUrl = 'https://app.mrbross.com'
// baseUrl = 'https://app-test-mrbross.source-solutions.co';//baseUrl = 'https://test-source3.source-solutions.co';

export const environment = {
  production: false,
  baseUrl: baseUrl,
  url: baseUrl + '/api/v1',
  appearance: 'outline', //legacy, outline, fill
  urlImages : '',
  profile_required: ["country", "phone"],
  msg_welcome_i18n: 'Bienvenido a Mr. Bross',
  required_for_transactions: ["profile", "city"],
  img_login: 'assets/mrbross.png',
  recaptcha: false,
  siteKey: '6Lfl_pgUAAAAABWfhQI5A5Zi_mmIoMu7EcSeFUjI',
  app: 'customer', //deliverer
  version: '1.1.0',
  firebaseConfig : {
    apiKey: "AIzaSyD1ILMEoRoBqjwvabE8oZJTD_TB6BNWXrw",
    authDomain: "mrbrossapp.firebaseapp.com",
    databaseURL: "https://mrbrossapp.firebaseio.com",
    projectId: "mrbrossapp",
    storageBucket: "mrbrossapp.appspot.com",
    messagingSenderId: "634441031613",
    appId: "1:634441031613:web:ec75c15d4aa2a6f5163cd4",
    measurementId: "G-LDHL98C86K"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, NgZone, ViewRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { RestService } from './../../../services/rest.service';
import { StateService } from './../../../services/state.service';
import { MapService } from './../../../services/map.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { BehaviorSubject } from 'rxjs';
import { Plugins } from '@capacitor/core';
const { Geolocation } = Plugins;

declare var google: any;
@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styles: [`
  agm-map {
    height: 100vh;
  }
  .centerMarker{
    position:absolute;
    background:url(http://maps.gstatic.com/mapfiles/markers2/marker.png) no-repeat;
    top:50%;left:50%;
    z-index:1;
    margin-left:-10px;
    margin-top:-34px;
    /*size of the image*/
    height:34px;
    width:20px;
    cursor:pointer;
  }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush  
})
export class BranchesComponent implements OnInit, OnDestroy, AfterViewInit {

  routeSubscription = null;
  maspBranchs = [];
  branches = [];
  allowEdit = false;
  positionCenter = null;
  zoom = 14;
  center = {lat: 3.488590, lng: -76.528900};
  center$ = new BehaviorSubject({lat: 3.488590, lng: -76.528900});
  polygons$ = new BehaviorSubject(null);
  polygon$ = new BehaviorSubject(null);
  branchId: any;
  profile = null;
  editing = false;
  currentPolygon = null;

  @ViewChild('searchInput', {static: false})  searchElementRef: ElementRef;

  constructor(private mapsAPILoader: MapsAPILoader, private rest: RestService, private state: StateService, 
    protected route: ActivatedRoute, private ngZone: NgZone, private mapService: MapService, 
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.center = {lat: 3.488590, lng: -76.528900};
    this.profile  = this.state.getData();
    Geolocation.getCurrentPosition().then((position) => {
        this.center = { lat: position.coords.latitude, lng: position.coords.longitude  };
    });      
    if(this.canEdit()){
      this.loadBranches();
    }else{
      this.loadBranch();
    }
  }
  
  modeEdit(){
    this.editing = true;
    //this.detectChanges();
  }
  canEdit(){
    const profile = this.profile.grupo_identificador;
    if(['super', 'admin_domicilios'].indexOf(profile) >= 0){
      return true;
    }
    return false;
  }

  loadBranch(){
    const polygons = [];
    const branch = this.state.getSubsidiary();
      if(branch.geo_cobertura){
        polygons.push(this.extractPolygon(branch.geo_cobertura));
      }
    //console.log(polygons, branch);
    this.polygons$.next(polygons);    
  }


  async getCurrentPosition(){
    const position = await Geolocation.getCurrentPosition();
    return { lat: position.coords.latitude, lng: position.coords.longitude  };
  }

  ngAfterViewInit() {
    this.initAutocomplete();
  } 

// avenida 6 norte 42n30
  initAutocomplete(){
    this.mapsAPILoader.load().then(() => {
      
    });
  }

  selectBranches($event){
    console.log($event)
    const polygons = [];
    /*for(const branch of $event.detail.value){
      this.branchId = branch.id;
      if(branch.geo_cobertura){
        polygons.push(this.extractPolygon(branch.geo_cobertura));
      }
    }*/
    this.branchId =  $event.detail.value.id;
    if($event.detail.value.geo_cobertura){
      polygons.push(this.extractPolygon($event.detail.value.geo_cobertura));
    }
    this.polylinePoints = [];
    this.currentPolygon = null;
    this.polygons$.next(polygons);
  }

  changePolygon($event){
    this.currentPolygon = $event.newArr[0];
  }
  cancel(){
    this.polylinePoints = [];
  }

  hasChanges(){
    let polygon = this.currentPolygon;
    if(this.polylinePoints && this.polylinePoints.length > 2){
      polygon = this.polylinePoints;
    }
    if(!polygon){
      return false;
    }
    return true;
  }
  savePolygon(){
    let polygon = this.currentPolygon;
    if(this.polylinePoints && this.polylinePoints.length > 2){
      polygon = this.polylinePoints;
    }
    if(!polygon){
      alert('No ha realizado cambios');
    }
    this.rest.post('domicile/edit-geocoverage-branch', { branchId: this.branchId, coords: polygon }).subscribe((response) => {
      if(response.item){
        let item = response.item;
        console.log(response.item);
        for (const key in this.branches) {
          if (this.branches.hasOwnProperty(key)) {
            // const element = this.branches[key];
            if(this.branches[key].id == item.id){
              // console.log(this.branches[key].id);
              // console.log(item.id);
              this.branches.splice(parseInt(key), 1);
              // console.log(this.branches);
              this.branches.push(item);
              this.detectChanges();
            } else{
              break;
            }
          }
        }
      }

    });
  }

  polylinePoints = [];
  mapClick($event){
    //console.log($event)
    if(this.editing){
      this.polylinePoints.push($event.coords);
      this.polygon$.next(this.polylinePoints);
    }
  }

  getLastLine(points){
    if(points && points.length > 2){
      return [points[0], points[points.length-1]];
    }
    return [];
  }



  
  ngOnDestroy() {
    for (const property in this) {
      if (this[property] && typeof this[property]['unsubscribe'] !== 'undefined') {
        this[property]['unsubscribe']();
      }
    }
  }

  loadBranches() {
    this.rest.post('domicile/active-branch', { }).subscribe((response) => {
      // this.dataSource$.next(response.items);
      if(response.items){
        for(const s of response.items){
          //console.log(s);
          //if(s.geo_cobertura){
            this.branches.push(s);
            //this.maspBranchs.push(this.extractPolygon(s.geo_cobertura));
         // }
          this.detectChanges();
        }      
      }
    });
  }

  extractPolygon(pointsStr: string){
    let strClean = pointsStr.replace(/\(/g, '');
    strClean = strClean.replace(/\)/g, '');
    const data = strClean.split(',')
 
    const points = [];
    for(let i = 0; i < (data.length - 1); i+=2){
      points.push({ lat: parseFloat(data[i]), lng: parseFloat(data[i+1])});
    }
    return points;
  }

  onMapReady(map) {
  }

  centerOfMapChange(event) {
  }

  zoomChange($event){
    //console.log($event)
    //this.center = Object.assign({}, this.positionCenter);
    //this.center$.next(this.center);
  }

  detectChanges(){
    if (this.cd !== null && this.cd !== undefined &&
          !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

}

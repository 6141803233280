export function isSmallDevice() {
    const w  = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
    if ( w > 770 ) {
        return false;
    }
    return true;
}

export const DefinedRegex = {
    money: '[^0-9\.,]',
    number: '[^0-9\.]',
    integer: '[^0-9]',
    double: '[^0-9\.]',
    name: '[^a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\\s\\-\\_]',
    slug: '[^a-z0-9_]',
    none: ''
  };

export function getRegex(regex = null) {
    if(regex) {        
        if(DefinedRegex[regex]) {
        return DefinedRegex[regex];
        }
        return regex;
    }
    return DefinedRegex['name'];    
}
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NavParams, MenuController, ToastController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Platform } from '@ionic/angular';
import { auth } from 'firebase/app';
import { UserInfo} from 'firebase/app'
import { AuthService } from './../../../services/auth.service';
import { RestService } from './../../../services/rest.service';
import { cfaSignIn, cfaSignOut, mapUserToUserInfo, cfaSignInPhoneOnCodeSent, cfaSignInPhoneOnCodeReceived} from 'capacitor-firebase-auth';
import { environment } from './../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal-profile-basic',
  template: `
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title *ngIf="step == 'country'" class="ml-3">En que país te encuentras?</ion-title>
      <ion-title *ngIf="step == 'phone'" class="ml-3">Validación</ion-title>
      <ion-title *ngIf="step == 'code'" class="ml-3">Código</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ng-container *ngIf="step == 'country'">
      <ng-container *ngIf="(countries$|async) as countries" >
        <cdk-virtual-scroll-viewport itemSize="50" [minBufferPx]="dimensions.height" [maxBufferPx]="dimensions.height" style=" height: calc(100vh);padding-left: 10px">
          <ng-container *cdkVirtualFor="let item of countries" >
               <ng-container *ngIf="isDividier(item); else itemTpl">
                  <ion-item-divider>
                    <ion-label style="padding-left:10px">{{item.name}} </ion-label>
                  </ion-item-divider>
                </ng-container>
                <ng-template #itemTpl>
                  <ion-item (click)="selectCountry(item)" detail>
                   <ion-label>{{item.name}} (+{{item.phone_code}})</ion-label>
                  </ion-item>
                 </ng-template>
           </ng-container>        
        </cdk-virtual-scroll-viewport>  
      </ng-container> 
    </ng-container>
    <ion-list class="mt-3 p-2">
      <ng-container *ngIf="step == 'phone'">
        <ion-item  class="input-item" lines="none" >
          <span> Ya casi terminamos, por favor escribe tú número celular.</span>
        </ion-item>
        <ion-item  class="input-item mt-3">
          <ion-label position="static">Celular: </ion-label>
          
          <ion-input type="number" [(ngModel)]="phone" placeholder="3207400000" required minlength="10" maxlength="10"></ion-input>
        </ion-item>
        <div id="recaptcha-container" class="text-center"></div>
        <div  class="input-item text-center mt-3" >
          <ion-button [disabled]="!isPhoneValid()" class="mt-3" size="small" (click)="submit()" shape="round" color="primary" >{{'Validate phone'|translate}}</ion-button>
          <!--ion-button  class="mt-3" size="small" (click)="saveBasicProfile()" shape="round" color="primary" >{{'Save'|translate}}</ion-button-->
        </div>
      </ng-container>
      <ng-container *ngIf="step == 'code'">
        <ion-item  class="input-item" lines="none" >
          <span> Ingresa el código que recibiste en el sms.</span>
        </ion-item>
        <ion-item  class="input-item mt-3">
          <ion-label position="static">Código:  </ion-label>
          
          <ion-input type="number" [(ngModel)]="code" placeholder="659585" required minlength="6" maxlength="6"></ion-input>
        </ion-item>
        <div id="recaptcha-container"></div>
        <div  class="input-item text-center mt-3" >
          <ion-button  class="mt-3" size="small" (click)="validateCodeWeb()" shape="round" color="primary" >{{'Validate code'|translate}}</ion-button>

        </div>
      </ng-container>

    </ion-list>
  </ion-content>
  <ion-footer>
    <ion-button class="mt-2" (click)="close()" color="dark"   expand="block">{{'Cancel'|translate}}</ion-button>
  </ion-footer>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalPhoneComponent implements OnInit {
  //contries = [];
  countries$ : BehaviorSubject<any> = new BehaviorSubject([]);
  country = null;
  step: string = "phone"; // country, phone, code
  lastDividier = "";
  confirmationResult = null;
  phone="";
  code="";
  dimensions: any;
  environment = environment;


  constructor(public menuCtrl: MenuController, public restService: RestService,
              public afAuth: AngularFireAuth, public cd: ChangeDetectorRef,
              public autService: AuthService, private modalController: ModalController,
              private platform: Platform, private navParams: NavParams) {
  }

  ngOnInit() {
    /*this.restService.postAnonimus('/commons/country/list', { _paginate: {max_rows: 500 }, _order: { order_by: 'name'  } }).subscribe((result) => {
      const countries = [];
      let letter = result.items[0].name[0];
      countries.push({ id: -1, name: letter })
      for(const item of result.items){
        if(letter != item.name[0]){
          letter = item.name[0];
          countries.push({ id: -1, name: letter })
        }
        countries.push(item);
      }
      this.countries$.next(countries);
    });*/
    this.selectCountry({'id': 1, 'nombre': 'Colombia', 'codigo': 'CO', 'phone_code': '57' });

    this.dimensions = this.getViewportSize();
  }
  getViewportSize() {
      return {
          width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
          height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      };
  }
  isDividier(item){
    return item.id == -1;
  }

  isEqualLastDividier(country){
    const l = country.name[0];
    console.log(l, this.lastDividier);
    if(l === this.lastDividier){
      return true;
    }
    this.lastDividier = l;
    return false;
  }
  selectCountry(country){
    this.step = 'phone';
    this.country = country;
    //this.cd.detectChanges();
  }

  isPhoneValid(){
    return (''+this.phone).length == 10;
  }

  submit(){
    if(this.platform.is('mobileweb')|| this.platform.is('desktop')){
      const applicationVerifier = new auth.RecaptchaVerifier('recaptcha-container');
      this.afAuth.auth.signInWithPhoneNumber('+' + this.country.phone_code + this.phone, applicationVerifier).then((confirmationResult: any) => {
       this.step = 'code';
       this.confirmationResult = confirmationResult;
       this.cd.detectChanges();
      }).catch((err)=>{
        console.log(err);
      })
    }else{
      cfaSignIn('phone', { phone: '+' + this.country.phone_code + this.phone }).pipe(mapUserToUserInfo()).subscribe(
          (user: any) => {
            console.log(user);
            this.saveBasicProfile(user);
          }, (err) => {
            console.log(err)
          }
        );

      cfaSignInPhoneOnCodeSent().subscribe(
        verificationId => console.log('---3--', verificationId)
      );

      // Android Only
      cfaSignInPhoneOnCodeReceived().subscribe(
        (event: {verificationId: string, verificationCode: string}) => console.log(`444 ${event.verificationId}:${event.verificationCode}`)
      )
    }
  }  
  validateCodeWeb(){
    console.log(this.code);
    this.confirmationResult.confirm(''+this.code).then((result) => {
      console.log(result);
       this.saveBasicProfile(result.user);
     });
  }

  saveBasicProfile(user){
    this.modalController.dismiss( { phone: '+' + this.country.phone_code + this.phone,
                                    commons_country_id: { id: this.country.id },
                                    firebase_phone_uid: user.uid
                                                 });
  }

  close(){
    this.modalController.dismiss( { cancel: true });
  }
}

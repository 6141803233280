import { Injectable } from '@angular/core';
// import { environment } from './../../environments/environment';
// import { SessionService } from './session.service';
// import { Socket } from 'ngx-socket-io';
// import { PosService } from './pos.service';
import { UtilPrintTxt } from '../lib/util-print-txt';
// import { UtilPrintTxt } from '../lib/util-print-txt';
// import { Empresa } from '../lib/models/empresa';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  utilPrint: any;
  // this.order = order;
  data: any = {
    header: [],
    subheader: [],
    info: [],
    items: [],
    totals: [],
    footer: []
  };  

  constructor() {
    this.utilPrint = new UtilPrintTxt();
    // this.utilPrint = new UtilPrintTxt(this.socket);
  }

  print(orden) {
    this.data.header = this.formatDataHeader();
    // console.log(orden.items);
    // console.log(orden.total);
    this.data.items = orden.items;
    this.data.info = this.getInfoOrder(orden);
    this.data.totals = this.getTotalsOrder(orden);

    const utilPrint = new UtilPrintTxt();
    const html = utilPrint.generateContentInvoice(this.data);

    // utilPrint.print(html, orden.createdAt);
    utilPrint.print(html);    
    return html;
  }

  formatDataHeader() {
    const header = [];
    header.push({ content: 'Orden de Domicilio', transform: 'uppercase' });
    return header;
  }

  getInfoOrder(orden) {
    let info = [];
      info.push({ content: orden.orderDate, transform: 'uppercase', linebreak: false });
      info.push({ content: 'Cliente: ' + orden.customerName.substr(0,25), transform: 'uppercase', linebreak: false });
      if (orden.address && orden.address.direccion) {
        // let direccion = orden.address.direccion;
        let direccion = orden.address.direccion.split(",");
        info.push({ content: 'Direccion: ' + direccion[0], transform: 'uppercase' });
        if(direccion[1] && direccion[2]){
          info.push({ content: direccion[1].trim() + ',' + direccion[2], transform: 'uppercase' });
        }
      }
      if (orden.address && orden.address.alias_dir) {
        info.push({ content: 'Indicaciones: ' + orden.address.alias_dir, transform: 'uppercase' });
      }      
      if (orden.customerPhone) {
        info.push({ content: 'Telefono: ' + orden.customerPhone, transform: 'uppercase' });
      }
      info.push({ content: '', style: 'border-top:2px solid #000; padding-bottom: 5px; text-align:left;' });
      if (orden.storePickup) {
        info.push({ content: '**El cliente recoge en el punto**', transform: 'uppercase', style: 'text-align: center; font-weight: bold;', linebreak: false });
      } else {
        info.push({ content: '**Enviar al domicilio**', transform: 'uppercase', style: 'text-align: center; font-weight: bold;', linebreak: false });
      }
      // info.push({ content: '', style: 'border-top:2px solid #000; padding-bottom: 5px; text-align:left;' });
      if (orden.paymentStatus == 'paidout') {
        info.push({ content: '', style: 'border-top:2px solid #000; padding-bottom: 5px; text-align:left;' });
        info.push({ content: '**PEDIDO PAGADO**', transform: 'uppercase', style: 'text-align: center; font-weight: bold;', linebreak: false });
        info.push({ content: 'Id No.: ' + orden.transactionId, transform: 'uppercase', style: '', linebreak: false });
        info.push({ content: 'Método Pago: ' + orden.transaction.data.payment_method.type, transform: 'uppercase', style: '', linebreak: false });
        if(orden.transaction.data.payment_method.extra.brand){
          info.push({ content: 'Franquicia Tarjeta: ' + orden.transaction.data.payment_method.extra.brand, transform: 'uppercase', style: '', linebreak: false });
        }
      } else {
        if(orden.method && orden.method.nombre){
          info.push({ content: '', style: 'border-top:2px solid #000; padding-bottom: 5px; text-align:left;' });
          info.push({ content: 'Método Pago: ' + orden.method.nombre, transform: 'uppercase', style: '', linebreak: false });
        }
      }
    return info;
  }

  getTotalsOrder(orden) {
    const styleLabel = 'text-align:left; padding-top:5px;';
    const stylecontent = 'padding-top:5px; text-align:left;';
    const stylerow = 'border-top:1px solid #000; text-align:left;';
    const total = [];

    if (orden.total) {
      total.push({ label: 'Subtotal:', content: orden.total, stylelabel: styleLabel, styleContent: stylecontent });
    }    
    if (orden.priceDomicile) {
      total.push({ label: 'Precio Domicilio: ', content: orden.priceDomicile, styleRow: stylerow, stylelabel: styleLabel, styleContent: stylecontent });
    }
    if (orden.gratification) {
      total.push({ label: 'Propina: ', content: orden.gratification, styleRow: stylerow, stylelabel: styleLabel, styleContent: stylecontent });
    }
    if (orden.discount) {
      total.push({ label: 'Descuento:', content: orden.discount, nature: 'negative', stylelabel: styleLabel, styleContent: stylecontent });
    }
    if (orden.totalPay) {
      total.push({ label: 'Total:', content: orden.totalPay, stylelabel: styleLabel, styleContent: stylecontent });
      if (orden.paymentStatus == 'paidout') {
        total.push({ label: 'Total A pagar:', content: '0.00', stylelabel: styleLabel, styleContent: stylecontent });
      } else{
        total.push({ label: 'Total A pagar:', content: orden.totalPay, stylelabel: styleLabel, styleContent: stylecontent });        
      }
    }
    return total;
  }

  printReportPayMethod(fechaInicio, fechaFin, data) {
    this.data.header = this.formatDataHeaderReport();
    this.data.items = data;
    this.data.info = this.getInfoOrderPaymentReport(fechaInicio, fechaFin);

    const utilPrint = new UtilPrintTxt();
    const html = utilPrint.generateContentReportPaymentMethod(this.data);
    utilPrint.print(html);    
    return html;
  }

  formatDataHeaderReport() {
    const header = [];
    header.push({ content: 'Reporte Por Forma Pago', transform: 'uppercase' });
    return header;
  }

  getInfoOrderPaymentReport(fechaInicio, fechaFin) {
    let info = [];
    info.push({ content: 'Desde: ' + new Date(fechaInicio).toLocaleString(), transform: 'uppercase', linebreak: false });
    info.push({ content: 'Hasta: ' + new Date(fechaFin).toLocaleString(), transform: 'uppercase', linebreak: false });
    return info;
  }

}

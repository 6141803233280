import { Pipe, PipeTransform } from '@angular/core';
import { isSmallDevice } from './../../util';

@Pipe({ name: 'fieldsize', pure: true })
export class FieldSizePipe implements PipeTransform {
  constructor() {
  }

  transform(config: any) {
    if (isSmallDevice()) {
      return 12;
    }
    if (config.size) {
      return config.size;
    }
    return 12;
  }
}

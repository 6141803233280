import { Component, OnInit } from '@angular/core';
import { NavParams, MenuController, ModalController, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Platform } from '@ionic/angular';
import { auth } from 'firebase/app';
import { UserInfo} from 'firebase/app'
import { cfaSignIn, mapUserToUserInfo} from 'capacitor-firebase-auth';
import { AuthService } from './../../../services/auth.service';
import { RestService } from './../../../services/rest.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-signin',
  template: `
  <ion-header>
    <ion-toolbar color="primary"><ion-title class="pl-3">{{'city_title'|translate}}</ion-title></ion-toolbar>
  </ion-header>
  <ion-content>
      <ng-container *ngIf="(cities$|async) as cities" >       
              
              <ion-item *ngFor="let item of cities" (click)="selectCity(item)" detail>
                  <ion-label>{{item.nombre}}</ion-label>
              </ion-item>
      </ng-container> 
  </ion-content>
  
  `
})
export class ModalCityComponent implements OnInit {
  loginForm: FormGroup; 
  dataProfile: any;
  results: any;
  cities$ : BehaviorSubject<any> = new BehaviorSubject([]);


  constructor(public menuCtrl: MenuController, 
              private restService: RestService,
              private modalController: ModalController,
              private platform: Platform, private navParams: NavParams) { 

  }

  ngOnInit() {
    let dataTmp = localStorage.getItem('tmp');
    if(dataTmp){
      this.dataProfile = JSON.parse(dataTmp);
    }

    this.restService.postAnonimus('domicile/available-cities', { _paginate: {max_rows: 500 }, _order: { order_by: 'name'  } }).subscribe((result) => {
      this.cities$.next(result.items);
    });
  } 

  selectCity(item){
    this.modalController.dismiss( { city: item  });
  }

}

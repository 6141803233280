import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { RestService } from './rest.service';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';
const { CapacitorDataStorageSqlite, Device } = Plugins;
import { Platform } from '@ionic/angular';

// import { observable, computed, action } from 'mobx';
const InitialStateAuth = { authenticated: false, data: {}, token: '' };

const InitialStateMenu = null;


@Injectable({
  providedIn: 'root'
})
export class StateService {
  auth = InitialStateAuth;
  storage = null;
  subsidiary = null;


  public auth$ = new BehaviorSubject(null);
  public store$ = new BehaviorSubject(null);

  constructor( private platform: Platform, private router: Router) {
    this.init();
  }
  async init(){

    /*if(this.platform.is('mobileweb')|| this.platform.is('desktop')){
      this.storage = CapacitorSQLPlugin.CapacitorDataStorageSqlite;
    }else{
      this.storage = CapacitorDataStorageSqlite;
    }*/
    this.storage = localStorage;
    const authState: any = await this.getJson('auth'); //.then((authState) => {
  
      if (authState.value) {
        this.auth = authState.value;
        this.auth$.next(this.auth);
      } else {
        this.auth = InitialStateAuth;
        this.auth$.next(InitialStateMenu);
      }

    const subsidiaryState: any = await this.getJson('subsidiary'); //.then((authState) => {
  
      if (subsidiaryState.value) {
        this.subsidiary = subsidiaryState.value;
      } else {
        this.subsidiary = null;

      }
      this.store$.next(this.subsidiary);
  }

  getJson(key: string){
    //return this.storage.get({ key: key });
    return new Promise((resolve, reject) => {
      const val = this.storage.getItem(key);
      if(!val || typeof val == 'undefined' || val == 'undefined'){
        resolve( { value: null });
      }else{
        resolve ({ value: JSON.parse(val) });
      }
    });    
  }

  setSubsidiary(s){
    this.subsidiary = s;
    this.persist('subsidiary', this.subsidiary);
    this.store$.next(this.subsidiary);
  }

  getSubsidiary(){
    const val = this.storage.getItem('subsidiary');
      if(!val || typeof val == 'undefined' || val == 'undefined'){
        return null;
      }else{
        return JSON.parse(val);
      }
  }
  storeInstalled(){
    return this.getSubsidiary();
  }

  persistAll() {
    this.persist('auth', this.auth);
    this.persist('subsidiary', this.subsidiary);
  }


  persist(key: string, value: any) {
    //return this.storage.set({key: key, value: JSON.stringify(value)});
    if(value){
      value['last_update'] = (new Date()).getTime();
    }
    this[key] = value;
    this.storage.setItem(key, JSON.stringify(value));
  }
  clear() {
    this.auth = InitialStateAuth;
    //this.subsidiary = null;
    this.persistAll();
    this.auth$.next(null);
  }
  authenticate(token: string, data: any, menu: any) {
    // console.log('authenticated ok', data);
    this.auth['authenticated'] = true;
    this.auth['data'] = data;
    this.auth['token'] = token;
    this.persist('auth', this.auth);
    this.auth$.next(this.auth);
  }

  updateAuth(data){
    this.auth.data = Object.assign(this.auth.data, data);
    this.persist('auth', this.auth);
  }

  setDataSession(data: any) {
    this.auth['data'] = data;
    this.auth$.next(this.auth);
    this.persist('auth', this.auth);
  }

  getAuthState() {
    return this.auth;
  }
  getToken() {
    if(this.auth.token){
      return (this.auth.token);
    }
    return (null);

  }
  getData() {    
    if(this.auth.data){
      // console.log(this.auth.data);
      return this.auth.data;
    }
    return null;
  }

  setGroups(groups) {    
    this.auth['groups'] = groups;
    this.persist('auth', this.auth);
    this.auth$.next(this.auth);
  }
  setToken(token) {    
    this.auth['token'] = token;
    this.persist('auth', this.auth);
    // this.auth$.next(this.auth);
  }
  getGroupIdentifier() {
    return this.auth['data']['group_identifier'];
  }

  isAuthenticated() {
    return new Promise(async (resolve, reject) => {
      if(this.auth){
        if(this.auth.authenticated){
          resolve(true);
        }else{
          resolve(false);
        }
      }else{
        await this.init();
        if(this.auth){
          if(this.auth.authenticated){
            resolve(true);
          }else{
            resolve(false);
          }
        }
      }
    });
  }

 
}

// const MoneyPipe = require('./money-pipe.js');
import { MoneyPipe } from './pipe/money.pipe';

export class UtilPrintTxt  {
  html = '';
  platform = 'web';
  mp = null;
  socket = null;
  index = 0;

  constructor( ) {
    this.mp = new MoneyPipe();
    // this.socket = socket;
  }
  /*
  printExec(file, cb=null){
    exec(' print '+path.join('orders', file+'.txt')+' ltp2 ', (error, stdout, stderr) => {
      console.log('File is created successfully.');
      this.index++;
      if(cb && this.index < 2){
        cb(file, cb)
      }
    });
  }

  print(txt, orderId='') {
      this.index = 0;
      // exec('echo "' + txt +'" | lp -H immediate -q 100 -d ZJ-58 -s ', (error, stdout, stderr) => { });
      // fs.writeFile(path.join('orders', orderId+'.txt'), txt, async (err) => {
      fs.writeFile(path.join(orderId+'.txt'), txt, async (err) => {        
        if (err) throw err; 
        // this.printExec(orderId)
      });
   }
*/
  print(html: any) {
    // console.log(html);
    return new Promise((resolve, reject) => {
      try {
        // tslint:disable-next-line:no-string-literal
        const newWin = window.frames['printf'];
        newWin.document.write( '<pre>' + html + '</pre>' );
        newWin.print();
        newWin.document.close();
        resolve();
      } catch (error) {
          console.log('er', error);
          reject(error);
        }
    });
  }


  
  configureSizeOrden() {
    const size = 16;
    return size + 'px';
  }

  renderHeader(data, $headerOn = true) {
    // this.html += `<p style="font-size: 16px;">`;
    this.html += ``;
    if ($headerOn) {
      for (const i in data.header) {
        if (data.header.hasOwnProperty(i)) {
          if ( data.header[i].linebreak === undefined) {
              if (data.header[i].linebreak !== false) {
                this.html += '\n';
              }
          } else {
            this.html += '';
          }
          if ( data.header[i].type === undefined) {
            let texto = data.header[i].content;
            if ( data.header[i].transform !== undefined && data.header[i].transform !== '') {
              if ( data.header[i].transform === 'uppercase') {
                if (texto && isNaN(texto) && texto !== null) {
                  if (typeof texto === 'string') {
                    texto = texto.toUpperCase();
                  }
                }
              }
            }
            this.html += texto;
            // this.html += '\n';
          }
        }
      }
    }
    // this.html += `</p>`;
    this.html += '\n';
    this.html += this.renderLine();
  }

  renderSubheader(data: any) {
    this.html += ``;
    for (const i in data.subheader) {
      if (data.subheader.hasOwnProperty(i)) {
        if ( data.subheader[i].type === undefined) {
          let texto = data.subheader[i].content;
          if ( data.subheader[i].transform !== undefined && data.subheader[i].transform !== '') {
            if ( data.subheader[i].transform === 'uppercase') {
              if (texto && isNaN(texto) && texto !== null) {
                if (typeof texto === 'string') {
                  texto = texto.toUpperCase();
                }
              }
            }
          }
          this.html += texto + '\n';
        }
      }
    }
    this.html += this.renderLine();
  }

  renderInfo(data) {
    console.log(data);
    for (const i in data.info) {
      if (data.info.hasOwnProperty(i)) {
        this.html += data.info[i].content + '\n';
      }
    }
    this.html += this.renderLine();
  }

  renderFooter(data=null, confEmpresa=null) {
    let date = new Date().toLocaleDateString();
    if (data != null) {
      for (const i in data.footer) {
        if (data.footer.hasOwnProperty(i)) {
          if ( data.footer[i].content !== undefined && data.footer[i].content !== '') {
            this.html += data.footer[i].content + '\n';
          }
        }
      }
    }
    if (confEmpresa) {
      if (confEmpresa.impresion_mostrar_hora) {
        date = new Date().toLocaleString();
      }
    }

    // this.html += '\n' + `Business 365 ` + date +  '\n';
    this.html += '\n' + date +  '\n';
    this.html += '\n\n';
  }

  /*********** Invoices **********/

  renderDetailItemInvoice(data) {
    let htmRef = '';
    // let htmVariante = '';
    // let htmAdicion = '';
    if (data.product.nombre) {
      // htmRef += '\n**'+data.product.nombre+' $ '+this.mp.transform(data.product.precio_venta);
      // htmRef += '\n**'+data.product.nombre+' $ '+this.mp.transform(this.calcDiscountPrice(data));      
      htmRef += '\n**'+data.product.nombre;
      let valorItem = data.product.precio_venta;
      if (this.hasPromo(data)) {
        valorItem = this.calcDiscountPrice(data);
      }
      let totalItem = (data.qt * valorItem);
      htmRef += '\n'+data.qt+' x $ '+this.mp.transform(valorItem) + ' = $ '+this.mp.transform(totalItem);
    }
    let optSec = false;
    if (this.hasPromo(data)) {
      // htmRef += '\n\nPromocion: '+this.getPromo(data)+'';
      optSec = true;
      // htmRef += '\n('+data.offer.descripcion+')';
    }
    if (data.options.length > 0) {
      if (data.optionsSecond.length > 0) {
        htmRef += '\n\nItem 1';
      }
      for (const option of data.options) {
        htmRef += '\n';
        htmRef += '->'+ this.getQtTopping(option, data) + ' x ' + option.nombre+' $ '+this.mp.transform(option.valor_adicional * this.getQtTopping(option, data));
      }
    }
    if(optSec){
      if (data.optionsSecond.length > 0) {
        htmRef += '\n\nItem 2';
        for (const optionSecond of data.optionsSecond) {
          htmRef += '\n';
          htmRef += '->'+ this.getQtTopping(optionSecond, data) + ' x ' + optionSecond.nombre+' $ '+this.mp.transform(optionSecond.valor_adicional * this.getQtTopping(optionSecond, data));
        }
      }
    }

    if (data.note) {
      const chunks = this.chunkSubstr('Nota: '+data.note, 50);
      htmRef += '\n' + chunks.join('\n');
    }
    // this.html +=  htmAdicion + htmRef + ' - ' + data.nombre_de_producto + htmVariante ;   
    this.html +=  htmRef;
    this.html += '\n';
  }
  getQtTopping(topp, item){
    if(topp.qt){
      return topp.qt* item.qt;
    }
    return 1 * item.qt;
  }
  chunkSubstr(str, size) {
    const numChunks = Math.ceil(str.length / size)
    const chunks = new Array(numChunks)

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size)
    }

    return chunks
  }


  calcDiscountPrice(item){
    return item.product.precio_venta - ( item.product.precio_venta * (this.calcDiscountPercentage(item) /100));
  }

  calcDiscountPercentage(item){
    return item.offer.porcentaje_descuento;
  }

  renderDetailItemPaymentMethod(data) {
    let htmRef = '';
    if (data.pay_name) {
      htmRef += '\n**'+data.pay_name+' $ '+this.mp.transform(data.total);
    }
    this.html +=  htmRef;
    this.html += '\n';
  }

  getPromo(item){
    // console.log(this.item)
    if(item.offer){
      if(item.offer.fact_tipo_descuento_id == 2){
        return '2x1';
      }
      if(item.offer.fact_tipo_descuento_id == 5){
        return 'disc';
      }
    }

    return ''; //this.order.getTypePromotion(item);
  }
  hasPromo(item){
    if(item.offer){
      return true;
    }
    return false;
  }

  renderItemsInvoice(data: any) {
    if (data.items.length > 0) {
      for (const i in data.items) {
        if (data.items.hasOwnProperty(i)) {
          this.renderDetailItemInvoice(data.items[i]);
          // this.renderDetailTotalItemInvoice(data.items[i]);
        }
      }
    }
  }

  renderItemsReportPaymentMethod(data: any) {
    if (data.items.length > 0) {
      for (const i in data.items) {
        if (data.items.hasOwnProperty(i)) {
          this.renderDetailItemPaymentMethod(data.items[i]);
          // this.renderDetailTotalItemInvoice(data.items[i]);
        }
      }
    }
  }

  renderTotalsInvoice(data: any) {
    if (data.totals.length > 0) {
      this.html += this.renderLine();
      for (const i in data.totals) {
        if (data.totals.hasOwnProperty(i)) {
          if ( data.totals[i].linebreak !== undefined) {
            if (data.totals[i].linebreak !== false) {
              this.html += '\n';
            }
          } else {
            this.html += this.completeZero(data.totals[i].label);
            if(data.totals[i].nature == 'negative'){
              this.html +=  '(-' + this.mp.transform(data.totals[i].content) + ')';
            } else{
              this.html +=  this.mp.transform(data.totals[i].content) + '';
            }
            this.html += '\n';
          }
        }
      }
      this.html += '\n';
    }
  }

  generateContentInvoice(data: any) {
    this.html = '';
    this.renderHeader(data);
    this.renderInfo(data);
    this.renderItemsInvoice(data);
    this.renderTotalsInvoice(data);
    // this.renderFooter(data, configEmpresa);
    return this.html;
  }

  generateContentReportPaymentMethod(data: any) {
    this.html = '';
    this.renderHeader(data);
    this.renderInfo(data);
    this.renderItemsReportPaymentMethod(data);
    return this.html;
  }

  /*********** Orders ************/

  renderDetailItemOrder(data: any) {
    let htmRef = '';
    if (data.referencia_de_producto) {
      htmRef = '(' + data.referencia_de_producto + ')';
    }

    if (data.addition) { this.html += '->'; }

    this.html += this.completeZero(data.cantidad + '', 4);
    this.html += this.completeZero(htmRef + ' ' +  data.nombre_de_producto.toUpperCase(), 26);
    this.html += this.completeZero(' ', 3);
    this.html += '\n';
    if (data.nombre_variante) {
      this.html +=  data.nombre_variante ;
      this.html += '\n';
    }
  }

  haveItems(data: any) {
    if (!data) {
      return false;
    }
    return Object.keys(data).length > 0;
  }

  renderDetailItemOpcionesOrder(data: any) {
    if ( this.haveItems(data.opciones_data)) {
      for (const i in data.opciones_data) {
        if (data.opciones_data.hasOwnProperty(i)) {
          let textOpcion = '';
          if (data.opciones_data[i] instanceof Array ) {
            for (const op in data.opciones_data[i]) {
              if (data.opciones_data[i].hasOwnProperty(op)) {
                textOpcion += data.opciones_data[i][op];
              }
            }
          } else {
            textOpcion = data.opciones_data[i];
          }
          this.html +=  this.completeZero(i, 12);
          this.html += ' -> ' + textOpcion + ' ';
          this.html += '\n';
        }
      }
    }

    if (data.descripcion !== '' && data.descripcion !== null) {
      this.html +=  data.descripcion ;
      this.html += '\n';
    }
  }

  renderItemsOrder(data: any) {
    if (data.items.length > 0) {
      for (const i in data.items) {
        if (data.items.hasOwnProperty(i)) {
          this.renderDetailItemOrder(data.items[i]);
          this.renderDetailItemOpcionesOrder(data.items[i]);
          this.html += this.renderLine();
        }
      }
    }
  }

  renderTotalsOrders(data: any) {
    if (data.totals.length > 0) {
      this.html += this.renderLine();
      for (const i in data.totals) {
        if (data.totals.hasOwnProperty(i)) {
          if ( data.totals[i].linebreak !== undefined) {
            if (data.totals[i].linebreak !== false) {
              this.html += '\n';
            }
          } else {
            this.html += this.completeZero(data.totals[i].label);
            this.html +=  this.mp.transform(data.totals[i].content) + '';
            this.html += '\n';
          }
        }
      }
      this.html += '\n';
    }
  }  

  generateContentOrder(data, configEmpresa) {
    this.html = '';
    //this.html += `<p style="font-size: 18px;">`;    
    this.renderHeader(data, false);
    this.renderSubheader(data);
    this.renderInfo(data);
    this.renderItemsOrder(data);
    this.renderTotalsOrders(data);    
    this.renderFooter(data, configEmpresa);
    //this.html += `</p>`;
    return this.html;
  }

  /**********Codebar Product***********/

  getImageCodeBar(text: any, format: any = 'EAN13') {
    const canvas = document.createElement('canvas');
    // JsBarcode(canvas, text, {format: format});
    return canvas.toDataURL('image/png');
  }

  renderCodebar(producto, cantidad) {
    for (let i = 0; i < cantidad; i++) {
      this.html += `<p class="center" style="padding-bottom:5px; margin-bottom:10px; border-bottom:solid #000 1px; font-size:15px;">`;
      this.html += '' + producto.nombre + '\n';
      this.html += '<img width="100%" src="' + this.getImageCodeBar(700000000013) + '">';
      this.html += `</p>`;
    }
  }

  generateContentCodebarProduct(producto: any, cantidad: number = 1, configEmpresa: any) {
    this.html = '';
    this.renderCodebar(producto, cantidad);
    this.renderFooter(null, configEmpresa);
    return this.html;
  }

  /************Products Turno *****************/

  renderDetailItemProductsTurno(data: any) {
    this.html += this.completeZero(data.nombre);
    this.html += this.completeZero( data.cantidad, 5 );
    this.html += data.total;
    this.html += '\n';
  }


  renderDetailItemTurnoResumenDomicilios(data) {
    if (data.title) {
      this.html += '<h4>' + data.title + '</h4>';
    }
    this.html += '';
    if (data.header) {
      // tslint:disable-next-line:prefer-const
      let idx = 0;
      for (const i in data.header) {
        if (data.header.hasOwnProperty(i)) {
          let cls = 1;
          if (idx === 0) {
            cls = 15;
          } else {
            cls = 9;
          }
          this.html += '<b>' + this.completeZero( data.header[i] , cls) + '</b>';
          idx++;
        }
      }
      this.html += '\n';
    }
    if (data.rows) {
      for (const i in data.rows) {
        if (data.rows.hasOwnProperty(i)) {
          let idx = 0;
          for (const key in data.rows[i]) {
            if (data.rows[i].hasOwnProperty(key)) {
              let cls = 1;
              if (idx === 0) {
                cls = 15;
              } else {
                cls = 9;
              }
              let text = data.rows[i][key];
              if (typeof text === 'undefined') {
                text = '';
              }
              if (typeof text === 'number') {
                text = this.mp.transform(text);
              }
              this.html += this.completeZero( text + '', cls);
              idx++;
            }
          }
          this.html += '\n';
        }
      }
    }

    if (data.total) {
      this.html += this.completeZero(' ' , 15);
      this.html += '<b>' + this.completeZero('Total', 9) + '</b>';
      this.html += this.completeZero(this.mp.transform(data.total), 9) ;
      this.html += this.completeZero(this.mp.transform(data.total_propina), 9);
      this.html += '\n';
    }
    this.html += '\n';
  }

  renderDetailItemTurnoResumenMeseros(data: any) {
    if (data.title) {
      this.html += '<h4>' + data.title + '</h4>';
    }
    this.html += '';

    if (data.header) {
      // tslint:disable-next-line:prefer-const
      let idx = 0;
      for (const i in data.header) {
        if (data.header.hasOwnProperty(i)) {
          let cls = 1;
          if (idx === 0) {
            cls = 15;
          } else {
            cls = 9;
          }
          this.html += '<b>' + this.completeZero( data.header[i] , cls) + '</b>';
          idx++;
        }
      }
      this.html += '\n';
    }
    if (data.rows) {
      for (const i in data.rows) {
        if (data.rows.hasOwnProperty(i)) {
          let idx = 0;
          for (const key in data.rows[i]) {
            if (data.rows[i].hasOwnProperty(key)) {
              let cls = 1;
              if (idx === 0) {
                cls = 15;
              } else {
                cls = 9;
              }
              let text = data.rows[i][key];
              if (typeof text === 'undefined') {
                text = '';
              }
              if (typeof text === 'number') {
                text = this.mp.transform(text);
              }
              this.html += this.completeZero( text + '', cls);
              idx++;
            }
          }
          this.html += '\n';
        }
      }
    }
    if (data.total) {
      this.html += this.completeZero(' ' , 15);
      this.html += '<b>' + this.completeZero('Total', 9) + '</b>';
      this.html += this.completeZero(this.mp.transform(data.total), 9) ;
      this.html += this.completeZero(this.mp.transform(data.total_propina), 9);
      this.html += '\n';
    }
    this.html += '\n';
  }

  renderDetailItemCuadroFiscal(data: any) {
    if (data.title) {
      this.html += '<h4>' + data.title + '</h4>';
    }

    this.html += '';

    if (data.header) {
      // tslint:disable-next-line:prefer-const
      let idx = 0;
      for (const i in data.header) {
        if (data.header.hasOwnProperty(i)) {
          let cls = 1;
          if (idx === 0) {
            cls = 15;
          } else {
            cls = 9;
          }
          this.html += '<b>' + this.completeZero( data.header[i] , cls) + '</b>';
          idx++;
        }
      }
      this.html += '\n';
    }
    if (data.rows) {
      for (const i in data.rows) {
        if (data.rows.hasOwnProperty(i)) {
          let idx = 0;
          for (const key in data.rows[i]) {
            if (data.rows[i].hasOwnProperty(key)) {
              let cls = 1;
              if (idx === 0) {
                cls = 16;
              } else {
                cls = 7;
              }
              this.html += this.completeZero( data.rows[i][key] + '', cls);
              idx++;
            }
          }
          this.html += '\n';
        }
      }
    }
    this.html += '\n';
  }

  renderDetailItemTurno(data: any) {
    let textsuma = '';
    if (data.suma_en_cuadre === false) {
      textsuma += '(no suma en cuadre)';
    }
    this.html += this.completeZero(data.nombre + textsuma + '');

    if (data.suma_en_cuadre === false) {
      this.html += '(';
    }
    this.html += this.mp.transform(data.total_sistema);
    if (data.suma_en_cuadre === false) {
      this.html += ')';
    }
    this.html += '\n';
  }

  renderItemsProductsTurno(data) {
    if (this.haveItems(data.items)) {
      for (const i in data.items) {
        if (data.items.hasOwnProperty(i)) {
          this.renderDetailItemProductsTurno(data.items[i]);
        }
      }
    }
  }

  renderItemsTurnoResumenDomicilios(data) {
    if (this.haveItems(data.items)) {
      for (const i in data.items) {
        if (data.items.hasOwnProperty(i)) {
          this.renderDetailItemTurnoResumenDomicilios(data.items[i]);
        }
      }
    }
  }

  renderItemsTurnoResumenMeseros(data) {
    if (this.haveItems(data.items)) {
      for (const i in data.items) {
        if (data.items.hasOwnProperty(i)) {
          this.renderDetailItemTurnoResumenMeseros(data.items[i]);
        }
      }
    }
  }

  renderItemsCuadreFiscal(data: any) {
    if (this.haveItems(data.items)) {
      for (const i in data.items) {
        if (data.items.hasOwnProperty(i)) {
          this.renderDetailItemCuadroFiscal(data.items[i]);
        }
      }
    }
  }

  renderItemsTurno(data: any) {
    if (this.haveItems(data.items)) {
      for (const i in data.items) {
        if (data.items.hasOwnProperty(i)) {
          this.renderDetailItemTurno(data.items[i]);
        }
      }
    }
  }

  generateContentProductsTurno(data: any, configEmpresa: any) {
    this.html = '';
    this.renderHeader(data);
    this.renderInfo(data);
    this.renderItemsProductsTurno(data);
    this.renderFooter(data, configEmpresa);
    return this.html;
  }

  generateContentTurn(data: any, configEmpresa: any) {
    this.html = '';
    this.renderHeader(data);
    this.renderInfo(data);
    this.renderItemsTurno(data);
    this.renderTotalsInvoice(data);
    this.renderFooter(data, configEmpresa);
    return this.html;
  }

  generateContentCuadreFiscal(data: any, configEmpresa: any) {
    this.html = '';
    this.renderHeader(data);
    this.renderInfo(data);
    this.renderItemsCuadreFiscal(data);
    this.renderFooter(data, configEmpresa);
    return this.html;
  }

  generateContentTurnoResumenDomicilios(data: any, configEmpresa: any) {
    this.html = '';
    this.renderHeader(data);
    this.renderInfo(data);
    this.renderItemsTurnoResumenDomicilios(data);
    this.renderFooter(data, configEmpresa);
    return this.html;
  }

  generateContentTurnoResumenMeseros(data: any, configEmpresa: any) {
    this.html = '';
    this.renderHeader(data);
    this.renderInfo(data);
    this.renderItemsTurnoResumenMeseros(data);
    this.renderFooter(data, configEmpresa);
    return this.html;
  }

  renderLine() {
    return '____________________________________' + '\r\n' + '\r\n';
  }

  strPad(str: any, padLength: number, padString: any, padType: any) {
    // console.log(str);
    const len = padLength - str.length;
    if (len < 0) {
      return str;
    }
    const pad = new Array(len + 1).join(padString);
    if (padType === 'STR_PAD_LEFT') {
      return pad + str;
    }
    return str + pad;
  }

  completeZero(text: any, size = 21, type = null) {
    return this.strPad(text, size, ' ', type);
  }
}

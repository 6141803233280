import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, NgZone, ViewRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { RestService } from '../../../services/rest.service';
import { StateService } from '../../../services/state.service';
import { FirestoreService } from '../../../services/firestore.service';
import {  ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import * as humanizeDuration from "humanize-duration";

declare var google: any;
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styles: [` `],
  changeDetection: ChangeDetectionStrategy.OnPush  
})
export class ActivityComponent implements OnInit, OnDestroy, AfterViewInit {

  firestoreActivity = null;
  branches = [];
  activities = {};

  constructor(private firestoreService: FirestoreService, private rest: RestService, private state: StateService, 
    protected route: ActivatedRoute, private cd: ChangeDetectorRef) { 

  }

  ngOnInit() {
   this.loadBranches();

  }

  getLastTime(id){
    //console.log(this.activities)
    if(this.activities[id] && this.activities[id].last_online){
      //console.log(this.activities[id].seconds)
      let dif = firebase.firestore.Timestamp.now().seconds - this.activities[id].last_online.seconds;
      if(dif == 0){
        dif = 1;
      }
      return humanizeDuration(dif*1000);
    }
    return null;
  }
  
  loadBranches() {
    this.rest.post('domicile/active-branch', { }).subscribe((response) => {
      // this.dataSource$.next(response.items);
      //console.log(response)
      if(response.items){
        this.branches = response.items;
        this.loadFirestoreData();
      }
    });
  }

  loadFirestoreData(){
    this.firestoreActivity = this.firestoreService.getAllDocuments('stores_activity').subscribe((snapshot) => {
      snapshot.forEach(doc => {
       console.log(doc.id, '=>', doc.data());
       this.activities[doc.id] = doc.data();
     });
     this.detectChanges();    
    }); 
  }
  
  deactivated(id){
    this.syncroStatus('busy', id);
  }
  activated(id){
    this.syncroStatus('online', id);
  }
  syncroStatus(status, id){
     // console.log('syncro...', status)
    //localStorage.setItem('status', status);
    const lh = firebase.firestore.FieldValue.serverTimestamp();
    this.firestoreService.createOrUpdateDocument('stores_activity', id+"", { last_online: lh, status: status, msg: 'pong'});
    //this.status = status;
    setTimeout(() => {
      this.loadFirestoreData();
    }, 1200)
    
    //this.detectChanges();
  }

  ngAfterViewInit() {
   // this.initAutocomplete();
  } 

  
  ngOnDestroy() {
    for (const property in this) {
      if (this[property] && typeof this[property]['unsubscribe'] !== 'undefined') {
        this[property]['unsubscribe']();
      }
    }
  }


  detectChanges(){
    if (this.cd !== null && this.cd !== undefined &&
          !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

}

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { RestService } from './../../../services/rest.service';
import { StateService } from './../../../services/state.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsComponent implements OnInit {

  displayedColumns: string[] = ['nombreProducto', 'activo'];
  dataSource$ = new BehaviorSubject([]);
  title = "Listado de Productos";
  routeSubscription = null;

  constructor(private rest: RestService, private state: StateService, protected route: ActivatedRoute) { }

  ngOnInit() {
    this.routeSubscription = this.route.url.subscribe(async (url) => {
      this.reload();
    });
  }

  ngOnDestroy() {
    for (const property in this) {
      if (this[property] && typeof this[property]['unsubscribe'] !== 'undefined') {
        this[property]['unsubscribe']();
      }
    }
  }

  reload() {
    const subsidiary: any = this.state.getSubsidiary();
    this.rest.post('domicile/active-products-branch', {subsidiary_id: subsidiary.id}).subscribe((response) => {
      this.dataSource$.next(response.items);
    });
  }

  deactivateProduct(element){
    console.clear();
    console.log("inactive", element);
    element.activo = false;
    this.rest.post('domicile/edit-products-branch', element).subscribe((response) => {
      console.log(response);
      this.reload();
    });
  }

  activateProduct(element){
    console.clear();
    console.log("active", element);
    element.activo = true;
    this.rest.post('domicile/edit-products-branch', element).subscribe((response) => {
      console.log(response)
      this.reload();
    });    
  }

}

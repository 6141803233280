import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { RestService } from './../../../services/rest.service';
import { StateService } from './../../../services/state.service';
import { PrintService } from './../../../services/print.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-report-summary',
  templateUrl: './report-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportSummaryComponent implements OnInit {

  displayedColumns: string[] = ['name', 'total'];
  dataSource$ = new BehaviorSubject([]);
  dataSourceBranches$ = new BehaviorSubject([]);
  dataSourceProducts$ = new BehaviorSubject([]);
  title = "Reporte De Ventas Por Forma de Pago";
  mostrarDatos = false;
  routeSubscription = null;
  form: FormGroup;

  constructor(private rest: RestService, private state: StateService, protected route: ActivatedRoute, private printService: PrintService) { }

  ngOnInit() {
    this.routeSubscription = this.route.url.subscribe(async (url) => {
      this.form = new FormGroup({
        'fecha_inicio': new FormControl('', Validators.required),
        'fecha_fin': new FormControl('', Validators.required)
      });
      // this.reload();
    });
  }

  ngOnDestroy() {
    for (const property in this) {
      if (this[property] && typeof this[property]['unsubscribe'] !== 'undefined') {
        this[property]['unsubscribe']();
      }
    }
  }

  search() {
    // console.log(this.form.value);
    const subsidiary: any = this.state.getSubsidiary();
    this.rest.post('domicile/report-summary', {subsidiary_id: subsidiary.id, date_range: this.form.value}).subscribe((response) => {
      // console.log(response.items);
      // if(response.items){
        this.mostrarDatos = true;
        this.dataSource$.next(response.methods);
        this.dataSourceBranches$.next(response.branches);
        this.dataSourceProducts$.next(response.products);
      // }
    });
  }

  print(){
    // console.log("Imprimiendo");
    const data = this.dataSource$.getValue();
    let fechaInicio = this.form.value['fecha_inicio'];
    let fechaFin = this.form.value['fecha_fin'];
    return this.printService.printReportPayMethod(fechaInicio, fechaFin, data);
  }

  isValid() {
    return this.form.valid && this.form.dirty;
  }

}

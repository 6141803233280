import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { RestService } from './../../../services/rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { StateService } from './../../../services/state.service';


@Component({
  selector: 'app-orders-deliver-store',
  templateUrl: './../orders/orders.component.html',
  styleUrls: ['./orders-deliver-store.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersDeliverStoreComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['fechaRegistro', 'customerName', 'customerPhone', 'address', 'storePickup', 'totalPay', 'actions'];
  dataSource$ = new BehaviorSubject([]);
  title = "Pedidos para entregar en restaurante";
  routeSubscription = null;
  subsidiary = null;

  constructor(private rest: RestService, protected route: ActivatedRoute, private state: StateService) { }

  ngOnInit() {
    this.routeSubscription = this.route.url.subscribe(async (url) => {
      this.reload();
    });   

  }
    ngOnDestroy() {
      for(const property in this) {
        if(this[property] && typeof this[property]['unsubscribe'] !== 'undefined'){
          this[property]['unsubscribe']();
        }
      }
    }
  reload(){
    this.subsidiary = this.state.getSubsidiary();
    this.rest.post('domicile/orders', { status: 'for-pickup', 'subsidiary': this.subsidiary.id }).subscribe((response) => {
      //console.log(response)
      this.dataSource$.next(response.items);
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ModalController, LoadingController, ActionSheetController, AlertController, ToastController, Platform } from '@ionic/angular';
import { StateService } from './state.service';
import { FirestoreService } from './firestore.service';
import { RestService } from './rest.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';


const defaultOrder = { items: [], priceDomicile: 0, created_at: null, address: null, subId: null, subInfo: null,
  deliverer: null, total: null, total_pay: null,  discount: null, gratification: null };

  @Injectable({
    providedIn: 'root'
  })
  export class OrderService {
    public $car = new BehaviorSubject(null);
    
    order = Object.assign({}, defaultOrder);


    constructor(private http: HttpClient,
      private rest: RestService,
      private firestore: FirestoreService,
      private stateService: StateService,
      private alertCtrl: AlertController,
      private translateService: TranslateService,
      public loadingController: LoadingController


      ) { 
      const ord = localStorage.getItem('order');
      //console.log(ord)
      if(ord){
        try{
          this.order = JSON.parse(ord);
          if(this.isGreaterThanNMinutes(9)){

          }
          this.$car.next(this.order);
        }catch(e){
          this.order = Object.assign({}, defaultOrder);
          this.$car.next(this.order);
        }
      }
    }

    calculateStimatedTime(ranges, lowTime, defaultValue=40){
      let t = defaultValue;
      const now = moment().format('H:mm');
      //console.log(moment().format('H:mm'))
      if(ranges){
        for(const range of ranges){
          if( now >= range[0] && now < range[1]){
            console.log('low demand');
            return lowTime;
          }
        }
      }
      return t;
    }
    updateOrderStatus(url, id, stimatedTime){
      console.log(url, id, stimatedTime)
      return new Promise(async (resolve, reject) => {
        try{
        /*const loading = await this.loadingController.create({
                message: '',
              });*/
              //await loading.present();
              this.rest.post(url, { id: id }).subscribe((result) => {
                
                //loading.dismiss();
                if(result.code == 'OK'){
                  this.firestore.createOrUpdateDocument('orders', id, 
                    { requestStates: result.item.data.log, status: result.item.data.status, stimatedTime: stimatedTime});
                  //this.requestStates$.next(result.item.data.log);
                  //this.orderData = result.item.data;
                }
                resolve(result);
                //this.detectChanges();
              }, (err) => {
                //loading.dismiss();
                resolve(null);
              });
            }catch(e){
              console.log(e)
            }

          })

    }
    setAddress(adr){
      this.order['address'] = adr;
    }

    setSubsidiaryId(id){
      this.order['subId'] = id;
    }

    setSubsidiaryInfo(info){
      this.order['subInfo'] = info;
    }

    createServerOrder(){
      return new Promise((resolve, reject) => {
        this.rest.post('domicile/create-order', this.order).subscribe((response: any) => {
          if(response.code == 'OK') {
            
            this.firestore.createOrUpdateDocument('orders', response.item.id, 
              { orderId: response.item.id, 
                //address: { adr: this.order.address.direccion, lat: this.order.address.lat, lng: this.order.address.lng },  
                //sucId: this.order.subId, subInfo: this.order.subInfo, 
                state: {id: 1, name: 'Orden Creada'} })
            resolve(response.item.id);
          }else{
            reject(response.msg);
          }
        });      
      });

    }

    isGreaterThanNMinutes(mins){
      if(this.order.created_at){
        const diffInMins = ( (new Date()).getTime() - this.order.created_at) / (1000*60);

        if(mins < diffInMins){
          this.order = Object.assign({}, defaultOrder);
        }
      }
      return false;
    }

    save(){
      if(!this.order.created_at){
        this.order.created_at = (new Date()).getTime();
      }
      localStorage.setItem('order', JSON.stringify(this.order));
      this.$car.next(this.order);
    }

    getPriceDomicile(){
      return this.order.priceDomicile;
    }

    setPriceDomicile(p){
      this.order.priceDomicile = parseFloat(p);
      this.save();
    }

    getItems(){
      return this.order.items;
    }

    getItem(_id){
      for(const i in this.order.items){
        if(_id == this.order.items[i]._id ){
          return this.order.items[i];
        }
      }
      return null;
    }

    getUnitPrice(item){
      return this.getSubtotalItem(item);
    }

    addQt(_id, qt=1){
      for(const i in this.order.items){
        if(_id == this.order.items[i]._id ){
          this.order.items[i].qt++;
        }
      }
      this.save();
    }
    minusQt(_id, qt=1){
      for(const i in this.order.items){
        if(_id == this.order.items[i]._id && this.order.items[i].qt > 1){
          this.order.items[i].qt--;
        }
      }
      this.save();
    }

    getDiscounts(){
      return 0;
    }

    getTotalToPay(){
      this.order.total_pay = this.getTotal() + this.getPriceDomicile() - this.getDiscounts();
      this.save();
      return this.order.total_pay;
    }
    getTotal(){
      let total = 0;
      for(const item of this.order.items){
        total += this.getSubtotalItem(item);
      }
      this.order.total = total;
      this.save();
      return total;    
    }
    getSubtotalItem(item){
      let subtotal = item.qt * parseFloat(item.product.precio_venta);
      for(const top of item.options){
        if(top.checked){
          if(top.valor_adicional > 0){
            subtotal += item.qt * parseFloat(top.valor_adicional);
          }
        }
      }
      return subtotal;
    }
    getTotalItems(){
      let total = 0;
      for(const item of this.order.items){
        total += item.qt;

      }
      return total;    
    }

    addProduct(item, product, qt, options, note){
      if(item){
        for(const i in this.order.items){
          if(item._id == this.order.items[i]._id ){
            this.order.items[i] = { _id: item._id, qt: qt, product: product, options: options, note: note }
          }
        }
      }else{
        this.order.items.push({ _id: (new Date()).getTime(), qt: qt, product: product, options: options, note: note });
      }
      
      this.save();
    }

    removeItem(_id){
      for(const i in this.order.items){
        if(_id == this.order.items[i]._id){
          this.order.items.splice(parseInt(i), 1);
          break;
        }
      }
      this.save();
    }



    async displayError(message : string) {
      let alert  = await this.alertCtrl.create({ header : 'Error',
        subHeader       : message,
        buttons      : ['Ok']
      });
      alert.present();
    }

    async displayMsg(message : string, header = '') {
      let alert  = await this.alertCtrl.create({ header : header,
        subHeader       : message,
        buttons      : ['Ok']
      });
      alert.present();
    }

    
  }
